import {
    AppBar,
    Backdrop,
    Breadcrumbs,
    Button,
    CircularProgress,
    createStyles,
    makeStyles,
    Theme,
    Toolbar,
    Typography,
} from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import { NavLink } from '../../utils/constants'
import { useAuth0 } from '../../utils/reactAuth0Wrapper'
import useI18n from '../../utils/testable/useI18n'
import testIds from '../../utils/testIds'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        main: {
            padding: '16px',
        },
        menu: {
            width: '250px',
        },
        menuIcon: {
            marginRight: '32px',
        },
        userName: {
            marginLeft: 'auto',
            marginRight: '32px',
        },
        link: {
            color: 'inherit',
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        circularProgress: {
            marginLeft: 'auto',
            marginRight: 'auto',
            color: '#F49704',
        },
        toolBar: {
            justifyContent: 'space-between',
        },
    }),
)

export interface TitlebarMenuProps {
    loading: boolean
    navigationList: NavLink[]
}

const TitlebarMenu = (props: TitlebarMenuProps): JSX.Element => {
    const classes = useStyles()
    const { t } = useI18n()
    const { isAuthenticated, logout, user } = useAuth0()
    const { loading, navigationList } = props

    return (
        <AppBar data-testid={'TitleBarMenu'} color={'primary'} position={'static'}>
            <Toolbar color={'inherit'} className={classes.toolBar}>
                <Breadcrumbs color={'inherit'}>
                    {navigationList.map((n) => {
                        if (n.link !== undefined) {
                            return (
                                <Typography color={'inherit'} key={n.link}>
                                    <Link
                                        data-testid={testIds.TitlebarMenu.onLinkClick}
                                        to={{
                                            pathname: n.link,
                                        }}
                                        className={classes.link}
                                    >
                                        {t(n.key)}
                                    </Link>
                                </Typography>
                            )
                        } else {
                            return (
                                <Typography data-testid={testIds.TitlebarMenu.text} color={'inherit'} key={n.key}>
                                    {t(n.key)}
                                </Typography>
                            )
                        }
                    })}
                </Breadcrumbs>
                <Backdrop className={classes.backdrop} open={loading}>
                    <CircularProgress className={classes.circularProgress} />
                </Backdrop>
                {isAuthenticated && user && (
                    <>
                        <Typography className={classes.userName}>{user.name}</Typography>
                        <Button
                            data-testid={testIds.TitlebarMenu.onLogoutClick}
                            onClick={() => logout({ returnTo: 'https://www.e-farm.com' })}
                        >
                            logout
                        </Button>
                    </>
                )}
            </Toolbar>
        </AppBar>
    )
}

export default TitlebarMenu
