import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    makeStyles,
    Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MultiAutocompleteDialog from 'components/MultiAutocompleteDialog'
import React from 'react'
import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    Droppable,
    DroppableProvided,
    DroppableStateSnapshot,
} from 'react-beautiful-dnd'
import { RouteComponentProps } from 'react-router-dom'
import AutocompleteDialog from '../../components/AutocompleteDialog'
import CategoryCreationButton from '../../components/CategoryCreationButton'
import CategoryTile from '../../components/CategoryTile'
import QuestionDialog from '../../components/QuestionDialog'
import QuestionTile from '../../components/QuestionTile'
import TitlebarMenu from '../../components/TitlebarMenu'
import { CategoryInternal, QuestionConfiguration, QuestionnaireType } from '../../graphql/types'
import { AutocompleteOption, DialogType, DragDropTypes } from '../../utils/constants'
import useI18n from '../../utils/testable/useI18n'
import { useQuestionnaireDetailData } from './useQuestionnaireDetailData'

const useStyles = makeStyles({
    main: {
        padding: '0 24px 16px 24px',
    },
    categoryWithMargin: {
        // marginTop: 16,
    },
    paper: { padding: '10px 32px 10px 16px' },
    question: {
        padding: '0 8px',
        marginTop: 4,
        marginBottom: 3,
        '&:focus': {
            outline: 0,
        },
    },
    uncategorized: {
        padding: '0 8px',
        marginTop: 16,
    },
    noFocus: {
        '&:focus': {
            outline: 0,
        },
    },
})

const QuestionnaireDetail = (prams: RouteComponentProps<any>): JSX.Element => {
    const classes = useStyles()
    const { t } = useI18n()
    const questionnaireID: string = prams.match.params.qid
    const machineTypeIdentifier: string = prams.match.params.id
    const {
        autocomplateCategoriesOptions,
        identifierCategoriesDisable,
        loadState,
        navigationList,
        onAddQuestions,
        onClickQuestionSelect,
        onCreateAutocomplate,
        onDeleteCategory,
        onDisableToggleCategory,
        onDragEnd,
        onDragStart,
        onEditQuestion,
        onRemoveQuestion,
        onSaveQuetion,
        questionSourceOptions,
        questionnaire,
        setState,
        state,
        uncategorizedQuestions,
    } = useQuestionnaireDetailData(machineTypeIdentifier, questionnaireID)

    const getListStyle = (isDraggingOver: boolean): React.CSSProperties => ({
        background: isDraggingOver ? '#ddd' : 'transparent',
        borderRadius: 8,
        minHeight: 30,
        margin: '15px 10px 0 10px',
        padding: '10px 0',
        transition: 'background 250ms',
    })
    const renderQuestions = (
        questionsIdentifier: string[],
        removable: boolean,
        categoryID: string,
        categoryIndex: number,
        questionnaireType: QuestionnaireType,
    ): JSX.Element[] => {
        return questionsIdentifier.map(
            (identifier: string, index: number): JSX.Element => {
                const questionConfiguration = questionnaire?.questionConfiguration.find(
                    (element) => element.questionDefinitionIdentifier === identifier,
                )

                return (
                    <Draggable key={identifier} draggableId={identifier} index={index}>
                        {(provided: DraggableProvided): JSX.Element => (
                            <Grid
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={classes.question}
                                xs={12}
                                item
                                key={identifier + '_' + index}
                            >
                                <QuestionTile
                                    identifier={identifier}
                                    onEdit={
                                        questionnaireType === QuestionnaireType.QUESTIONNAIRE
                                            ? (): void => onEditQuestion(identifier)
                                            : undefined
                                    }
                                    deleted={questionConfiguration?.questionDefinition.deleted === true}
                                    selected={state.selectedDragQuestions.indexOf(identifier) !== -1}
                                    currentlyDragging={state.currentlyDragging}
                                    onClick={onClickQuestionSelect(identifier, categoryID)}
                                    onRemovePress={(): void => onRemoveQuestion(index, categoryIndex)}
                                    removable={removable}
                                />
                            </Grid>
                        )}
                    </Draggable>
                )
            },
        )
    }
    const renderCategories = (categories: CategoryInternal[], questionnaireType: QuestionnaireType): JSX.Element[] => {
        return categories.map(
            (category: CategoryInternal, index: number): JSX.Element => {
                return (
                    <Draggable key={category.id} draggableId={category.id} index={index}>
                        {(provided: DraggableProvided): JSX.Element => (
                            <Accordion
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel-content"
                                    id="panel-header"
                                >
                                    {/* <Grid item xs={12} className={classes.noFocus}> */}
                                    <Grid container item className={classes.categoryWithMargin}>
                                        <Grid item xs={12}>
                                            <CategoryTile
                                                category={category}
                                                onAddQuestion={(): void =>
                                                    setState({
                                                        ...state,
                                                        openDialog: DialogType.ADD_QUESTION,
                                                        dialogIndex: index,
                                                    })
                                                }
                                                onEdit={(): void =>
                                                    setState({
                                                        ...state,
                                                        openDialog: DialogType.EDIT_CATEGORY,
                                                        dialogIndex: index,
                                                    })
                                                }
                                                index={index}
                                                onDelete={(): void =>
                                                    setState({
                                                        ...state,
                                                        openDialog: DialogType.DELETE_CATEGORY,
                                                        dialogIndex: index,
                                                    })
                                                }
                                                onDisableToggle={(): void => onDisableToggleCategory(index)}
                                            />
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails style={{ backgroundColor: 'rgb(245,245,245)' }}>
                                    <Droppable type={DragDropTypes.QUESTION} droppableId={category.id}>
                                        {(
                                            provided: DroppableProvided,
                                            snapshot: DroppableStateSnapshot,
                                        ): JSX.Element => (
                                            <Grid
                                                item
                                                xs={12}
                                                ref={provided.innerRef}
                                                style={getListStyle(snapshot.isDraggingOver)}
                                            >
                                                {renderQuestions(
                                                    category.questions,
                                                    true,
                                                    category.id,
                                                    index,
                                                    questionnaireType,
                                                )}
                                                {provided.placeholder}
                                            </Grid>
                                        )}
                                    </Droppable>
                                </AccordionDetails>
                                {/* </Grid> */}
                            </Accordion>
                        )}
                    </Draggable>
                )
            },
        )
    }

    const renderAutocomplateDialog = (
        identifierDisable: string[],
        options: AutocompleteOption[],
        type: DialogType | null,
        index: number | null,
        value?: AutocompleteOption,
    ): JSX.Element => {
        return (
            <>
                {type !== null && index !== null && (
                    <AutocompleteDialog
                        identifierDisable={identifierDisable}
                        onCLose={(): void =>
                            setState({
                                ...state,
                                openDialog: null,
                                dialogIndex: null,
                            })
                        }
                        onCreate={(name: AutocompleteOption | null, inputName: string): void => {
                            onCreateAutocomplate(name, inputName, type, index)
                            setState({
                                ...state,
                                openDialog: null,
                                dialogIndex: null,
                            })
                        }}
                        options={options}
                        open={type !== null}
                        type={type}
                        value={value}
                    />
                )}
            </>
        )
    }

    const renderAddQuestionsDialog = (options: AutocompleteOption[], open: boolean, index: number): JSX.Element => {
        return (
            <MultiAutocompleteDialog
                onCLose={(): void =>
                    setState({
                        ...state,
                        openDialog: null,
                        dialogIndex: null,
                    })
                }
                onCreate={(questions: AutocompleteOption[]): void => {
                    onAddQuestions(questions, index)
                    setState({
                        ...state,
                        openDialog: null,
                        dialogIndex: null,
                    })
                }}
                options={options}
                open={open}
                value={[]}
            />
        )
    }
    const renderDeleteCategoryDialog = (
        open: DialogType | null,
        index: number | null,
        categories: CategoryInternal[],
    ): JSX.Element => {
        return (
            <>
                {open !== null && index !== null && (
                    <Dialog
                        open={open === DialogType.DELETE_CATEGORY}
                        onClose={(): void =>
                            setState({
                                ...state,
                                openDialog: null,
                                dialogIndex: null,
                            })
                        }
                    >
                        <DialogContent>
                            <Typography>
                                {t('confirmDeletePDFCategoryMessage')}
                                {t(categories[index].translationKey)}
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={(): void =>
                                    setState({
                                        ...state,
                                        openDialog: null,
                                        dialogIndex: null,
                                    })
                                }
                            >
                                {t('cancel')}
                            </Button>
                            <Button
                                onClick={(): void => {
                                    onDeleteCategory(index)
                                    setState({
                                        ...state,
                                        openDialog: null,
                                        dialogIndex: null,
                                    })
                                }}
                            >
                                {t('confirm')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}
            </>
        )
    }
    const renderQuestionDialog = (
        open: DialogType | null,
        index: number | null,
        question: QuestionConfiguration,
        questionSourceOp: AutocompleteOption[],
    ): JSX.Element => {
        return (
            <>
                {open !== null && index !== null && (
                    <QuestionDialog
                        onClose={(): void =>
                            setState({
                                ...state,
                                openDialog: null,
                                dialogIndex: null,
                            })
                        }
                        onSave={(q: QuestionConfiguration): void => {
                            onSaveQuetion(q)
                            setState({
                                ...state,
                                openDialog: null,
                                dialogIndex: null,
                            })
                        }}
                        open={open === DialogType.EDIT_QUESTION}
                        question={question}
                        questionSourceOptions={questionSourceOp}
                    />
                )}
            </>
        )
    }

    const renderHeader = React.useCallback(
        (): JSX.Element => (
            <Grid container item direction={'row'} xs={12}>
                <TitlebarMenu
                    loading={loadState.loading && loadState.errors === undefined}
                    navigationList={navigationList}
                />
            </Grid>
        ),
        [loadState.errors, loadState.loading, navigationList],
    )
    return (
        <>
            <Grid container className={classes.main} direction={'row'} spacing={6}>
                <Grid item xs={12} style={{ padding: '24px 24px 0 24px' }}>
                    {renderHeader()}
                    <CategoryCreationButton
                        onClick={(): void =>
                            setState({
                                ...state,
                                openDialog: DialogType.CREATE_CATEGORY,
                                dialogIndex: -1,
                            })
                        }
                    />
                </Grid>
                {questionnaire && loadState.errors === undefined && (
                    <>
                        {state.openDialog === DialogType.CREATE_CATEGORY &&
                            state.dialogIndex !== null &&
                            renderAutocomplateDialog(
                                identifierCategoriesDisable(questionnaire.categories),
                                autocomplateCategoriesOptions,
                                DialogType.CREATE_CATEGORY,
                                state.dialogIndex,
                            )}
                        {state.openDialog === DialogType.EDIT_CATEGORY &&
                            state.dialogIndex !== null &&
                            renderAutocomplateDialog(
                                identifierCategoriesDisable(questionnaire.categories),
                                autocomplateCategoriesOptions,
                                DialogType.EDIT_CATEGORY,
                                state.dialogIndex,
                                {
                                    identifier: questionnaire.categories[state.dialogIndex].translationKey,
                                    translation: t(questionnaire.categories[state.dialogIndex].translationKey),
                                },
                            )}
                        {state.openDialog === DialogType.ADD_QUESTION &&
                            state.dialogIndex !== null &&
                            renderAddQuestionsDialog(
                                questionSourceOptions,
                                state.openDialog === DialogType.ADD_QUESTION,
                                state.dialogIndex,
                            )}
                        {state.openDialog === DialogType.DELETE_CATEGORY &&
                            renderDeleteCategoryDialog(state.openDialog, state.dialogIndex, questionnaire.categories)}
                        {state.openDialog === DialogType.EDIT_QUESTION &&
                            state.dialogIndex !== null &&
                            renderQuestionDialog(
                                state.openDialog,
                                state.dialogIndex,
                                questionnaire.questionConfiguration[state.dialogIndex],
                                questionSourceOptions,
                            )}
                        <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
                            <Grid container className={classes.main}>
                                <Droppable droppableId="categories" type={DragDropTypes.CATEGORY}>
                                    {(provided: DroppableProvided): JSX.Element => (
                                        <Grid item xs={12} ref={provided.innerRef}>
                                            {renderCategories(questionnaire.categories, questionnaire.type)}
                                            {provided.placeholder}
                                        </Grid>
                                    )}
                                </Droppable>

                                <Grid item xs={12} className={classes.uncategorized}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel-content"
                                            id="panel-header"
                                        >
                                            <Grid container direction={'row'} alignItems={'center'}>
                                                <Grid item xs={10}>
                                                    <Typography>{t('uncategorized')}</Typography>
                                                </Grid>
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails style={{ backgroundColor: 'rgb(245,245,245)' }}>
                                            <Droppable
                                                type={DragDropTypes.QUESTION}
                                                droppableId="uncategorized_questions"
                                            >
                                                {(provided: DroppableProvided): JSX.Element => (
                                                    <Grid item xs={12} ref={provided.innerRef}>
                                                        {renderQuestions(
                                                            uncategorizedQuestions,
                                                            false,
                                                            'uncategorized_questions',
                                                            -1,
                                                            questionnaire.type,
                                                        )}
                                                        {provided.placeholder}
                                                    </Grid>
                                                )}
                                            </Droppable>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>
                        </DragDropContext>
                    </>
                )}
            </Grid>
        </>
    )
}

export default QuestionnaireDetail
