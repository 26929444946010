import { createMuiTheme } from '@material-ui/core/styles'

export default createMuiTheme({
    palette: {
        primary: { 500: '#55a546' },
        secondary: { main: '#ff6600' },
    },
    props: {
        MuiButtonBase: {
            disableRipple: true,
        },
    },
    // override styles here
    overrides: {
        MuiAccordion: {
            root: {
                '& .MuiCollapse-hidden': {
                    display: 'none !important',
                },
            },
        },
    },
})
