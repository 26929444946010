import {
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    Fade,
    Grid,
    IconButton,
    makeStyles,
    Tooltip,
    Typography,
} from '@material-ui/core'
import { Delete, FileCopy, Visibility, VisibilityOff } from '@material-ui/icons'
import React from 'react'
import { HashRouter, Link } from 'react-router-dom'
import { AutocompleteOption } from '../../utils/constants'
import { disabledSaveAutocomplete } from '../../utils/testable/autoCompleteFunc'
import useI18n from '../../utils/testable/useI18n'
import testIds from '../../utils/testIds'
import AutocompleteForm from '../AutocompleteForm'

const useStyles = makeStyles({
    main: {
        height: 185,
    },
    cardContent: {
        height: 'calc(100% - 32px)',
    },
    cardActionArea: {
        height: '72%',
    },
    iconButton: {
        color: '#DDD9C3',
    },
    iconButtonMaster: {
        color: '#55A546',
    },
    editBottomButtonRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginLeft: 'auto',
        minWidth: '70%',
    },
    editMain: {
        display: 'flex',
        flexDirection: 'column',
        height: 185,
    },
    editCardContent: {
        height: 'auto',
    },
    title: {
        margin: 4,
        wordWrap: 'break-word',
    },
})

export interface QuestionnaireTileProps {
    disabled: boolean
    id: string
    identifier: string
    identifierDisable: string[]
    machineTypeIdentifier: string
    onDeleteClick: (id: string) => void
    onDuplicateClick: (id: string) => void
    onDisableClick: (id: string) => void
    onEditClick: (name: AutocompleteOption | null, inputName: string, id: string) => void
    options: AutocompleteOption[]
}

interface StateType {
    name: AutocompleteOption | null
    inputName: string
    showForm: boolean
}
const QuestionnaireTile = (props: QuestionnaireTileProps): JSX.Element => {
    const classes = useStyles()
    const { t } = useI18n()
    const {
        disabled,
        id,
        identifier,
        identifierDisable,
        machineTypeIdentifier,
        onDeleteClick,
        onDuplicateClick,
        onDisableClick,
        onEditClick,
        options,
    } = props

    const [state, setState] = React.useState<StateType>({
        name: { identifier, translation: t(identifier) },
        inputName: identifier,
        showForm: false,
    })

    return (
        <>
            {!state.showForm && (
                <Card className={classes.main} key={id}>
                    <HashRouter>
                        <Link
                            data-testid={testIds.QuestionnaireTile.onClick}
                            to={{
                                pathname: '/machineType/' + machineTypeIdentifier + '/questionnaire/' + id,
                            }}
                            style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                            <CardActionArea className={classes.cardActionArea}>
                                <CardContent className={classes.cardContent}>
                                    <Grid item xs>
                                        <Typography variant={'h6'} className={classes.title} gutterBottom>
                                            {t(identifier)}
                                        </Typography>
                                    </Grid>
                                </CardContent>
                            </CardActionArea>
                        </Link>
                    </HashRouter>
                    <CardActions disableSpacing>
                        <Button
                            data-testid={testIds.QuestionnaireTile.onEditShowForm}
                            color="primary"
                            onClick={(): void => setState({ ...state, showForm: true })}
                        >
                            <Typography>{t('questionnaireTileEdit')}</Typography>
                        </Button>
                        <span style={{ marginLeft: 'auto', flexDirection: 'row' }}>
                            <Tooltip
                                title={t('duplicateQuestionnaire')}
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                arrow
                                placement={'bottom'}
                            >
                                <IconButton
                                    data-testid={testIds.QuestionnaireTile.onDuplicate}
                                    className={classes.iconButton}
                                    size={'small'}
                                    onClick={() => onDuplicateClick(id)}
                                >
                                    <FileCopy />
                                </IconButton>
                            </Tooltip>
                            <Tooltip
                                title={t('deleteQuestionnaire')}
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                arrow
                                placement={'bottom'}
                            >
                                <IconButton
                                    data-testid={testIds.QuestionnaireTile.onTileMenuDelete}
                                    className={classes.iconButton}
                                    size={'small'}
                                    onClick={() => onDeleteClick(id)}
                                >
                                    <Delete />
                                </IconButton>
                            </Tooltip>
                            <Tooltip
                                title={disabled ? t('enableQuestionnaire') : t('disableQuestionnaire')}
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                arrow
                                placement={'bottom'}
                            >
                                <IconButton
                                    data-testid={testIds.QuestionnaireTile.onDisable}
                                    className={!disabled ? classes.iconButtonMaster : classes.iconButton}
                                    size={'small'}
                                    onClick={() => onDisableClick(id)}
                                >
                                    {disabled ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </Tooltip>
                        </span>
                    </CardActions>
                </Card>
            )}
            {state.showForm && (
                <Card className={classes.editMain} key={id}>
                    <CardContent className={classes.editCardContent}>
                        <Grid item xs>
                            <Typography variant={'h6'} gutterBottom noWrap>
                                {t('newQuestionnaireTileTitle')}
                            </Typography>
                            <AutocompleteForm
                                testId={'QuestionnaireTile'}
                                identifierDisable={identifierDisable}
                                inputValue={state.inputName}
                                onChange={(value: AutocompleteOption | null): void =>
                                    setState({ ...state, name: value })
                                }
                                onInputChange={(inputValue: string): void =>
                                    setState({ ...state, inputName: inputValue, name: null })
                                }
                                options={options}
                                value={state.name}
                                label={t('newQuestionnaireTileName')}
                            />
                        </Grid>
                    </CardContent>
                    <CardActions disableSpacing>
                        <Grid className={classes.editBottomButtonRow}>
                            <Button
                                data-testid={testIds.QuestionnaireTile.onEditCancel}
                                color="primary"
                                onClick={(): void => setState({ name: null, inputName: '', showForm: false })}
                            >
                                <Typography noWrap>{t('labelCancel')}</Typography>
                            </Button>
                            <Button
                                data-testid={testIds.QuestionnaireTile.onEditConfirm}
                                variant="contained"
                                color="primary"
                                disabled={disabledSaveAutocomplete(state.inputName, identifierDisable, options)}
                                onClick={(): void => {
                                    onEditClick(state.name, state.inputName, id)
                                    setState({ name: null, inputName: '', showForm: false })
                                }}
                            >
                                <Typography noWrap>{t('labelSave')}</Typography>
                            </Button>
                        </Grid>
                    </CardActions>
                </Card>
            )}
        </>
    )
}

export default QuestionnaireTile
