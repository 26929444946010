import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import React from 'react'
import { DragStart, DropResult, ResponderProvided } from 'react-beautiful-dnd'
import categoryCreate from '../../graphql/mutations/categoryCreate'
import categoryDelete from '../../graphql/mutations/categoryDelete'
import categoryDisable from '../../graphql/mutations/categoryDisable'
import categoryDragDrop from '../../graphql/mutations/categoryDragDrop'
import categoryEdit from '../../graphql/mutations/categoryEdit'
import questionDelete from '../../graphql/mutations/questionDelete'
import questionMultiAdd from '../../graphql/mutations/questionMultiAdd'
import questionReAdd from '../../graphql/mutations/questionReAdd'
import questionSave from '../../graphql/mutations/questionSave'
import getQuestionnaire from '../../graphql/queries/getQuestionnaire'
import { CategoryInternal, QuestionConfiguration, QuestionnaireInternal } from '../../graphql/types'
import { AutocompleteOption, DialogType, DragDropTypes, LoadState, NavLink } from '../../utils/constants'
import useI18n from '../../utils/testable/useI18n'
import { useTranslation } from '../../utils/TranslationWrapper'
import { useConditionsOptions } from '../../utils/useConditionsOptions'

interface StateType {
    currentCategory: string | null
    currentlyDragging: string | null
    dialogIndex: number | null
    openDialog: DialogType | null
    questionAnchor: string | null
    selectedDragQuestions: string[]
}
interface QuestionnaireDetailDataReturn {
    autocomplateCategoriesOptions: AutocompleteOption[]
    questionSourceOptions: AutocompleteOption[]
    identifierCategoriesDisable: (categories: CategoryInternal[]) => string[]
    questionnaire: QuestionnaireInternal | undefined
    loadState: LoadState
    navigationList: NavLink[]
    state: StateType
    setState: React.Dispatch<React.SetStateAction<StateType>>
    onCreateAutocomplate: (name: AutocompleteOption | null, inputName: string, type: DialogType, index: number) => void
    onClickQuestionSelect: (
        identifier: string,
        categoryID: string,
    ) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    onRemoveQuestion: (index: number, categoryIndex: number) => void
    onEditQuestion: (identifier: string) => void
    onSaveQuetion: (quetion: QuestionConfiguration) => void
    onDragStart: (initial: DragStart) => void
    onDragEnd: (result: DropResult, provided: ResponderProvided) => void
    onDeleteCategory: (index: number) => void
    onDisableToggleCategory: (index: number) => void
    onAddQuestions: (questions: AutocompleteOption[], index: number) => void
    uncategorizedQuestions: string[]
}

export const useQuestionnaireDetailData = (
    machineTypeIdentifier: string,
    questionnaireID: string,
): QuestionnaireDetailDataReturn => {
    const { t } = useI18n()
    const { setQuestionnaireForConditions } = useConditionsOptions()

    const [questionnaire, setQuestionnaire] = React.useState<QuestionnaireInternal | undefined>(undefined)
    const [state, setState] = React.useState<StateType>({
        currentCategory: null,
        currentlyDragging: null,
        dialogIndex: null,
        openDialog: null,
        questionAnchor: null,
        selectedDragQuestions: [],
    })
    const [loadState, setLoadState] = React.useState<LoadState>({
        loading: true,
        errors: undefined,
    })

    const [getQuestionnaireQuery, getQuestionnaireQueryStatus] = useLazyQuery(getQuestionnaire, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        variables: {
            id: questionnaireID,
        },
        onCompleted: (data: any) => {
            if (data && data.questionnaire) {
                setQuestionnaire(data.questionnaire)
                setQuestionnaireForConditions(data.questionnaire)
                setLoadState({ ...loadState, loading: false })
            }
        },
    })

    const [categoryDragDropHandler, categoryDragDropHandlerStatus] = useMutation(categoryDragDrop, {
        refetchQueries: ['getQuestionnaire'],
    })
    const [questionDeleteHandler, questionDeleteHandlerStatus] = useMutation(questionDelete, {
        refetchQueries: ['getQuestionnaire'],
    })
    const [categoryDeleteHandler, categoryDeleteHandlerStatus] = useMutation(categoryDelete, {
        refetchQueries: ['getQuestionnaire'],
    })
    const [categoryDisableHandler, categoryDisableHandlerStatus] = useMutation(categoryDisable, {
        refetchQueries: ['getQuestionnaire'],
    })
    const [questionReAddHandler, questionReAddHandlerStatus] = useMutation(questionReAdd, {
        refetchQueries: ['getQuestionnaire'],
    })
    const [questionMultiAddHandler, questionMultiAddHandlerStatus] = useMutation(questionMultiAdd, {
        refetchQueries: ['getQuestionnaire'],
    })
    const [questionSaveHandler, questionSaveHandlerStatus] = useMutation(questionSave, {
        refetchQueries: ['getQuestionnaire'],
    })
    const [categoryCreateHandler, categoryCreateHandlerStatus] = useMutation(categoryCreate, {
        refetchQueries: ['getQuestionnaire', 'loadTranslations'],
    })
    const [categoryEditHandler, categoryEditHandlerStatus] = useMutation(categoryEdit, {
        refetchQueries: ['getQuestionnaire', 'loadTranslations'],
    })
    const getCategoryQuestionList = (id: string, categories: CategoryInternal[]): string[] => {
        const category = categories.find((cat) => cat.id === id)
        return category ? category.questions : []
    }
    const getCategoryIndex = (id: string, categories: CategoryInternal[]): number => {
        return categories.findIndex((cat) => cat.id === id)
    }
    const uncategorizedQuestions: string[] = React.useMemo(() => {
        const returnArray: string[] = []
        const categoryQuestionList: string[] = []
        if (questionnaire) {
            questionnaire.categories.forEach((cat) => {
                categoryQuestionList.push(...cat.questions)
            })
            questionnaire.questionConfiguration.forEach((qc) => {
                if (
                    !categoryQuestionList.includes(qc.questionDefinitionIdentifier) &&
                    !returnArray.includes(qc.questionDefinitionIdentifier)
                )
                    returnArray.push(qc.questionDefinitionIdentifier)
            })
        }
        return returnArray
    }, [questionnaire])
    const questionSourceOptions: AutocompleteOption[] = React.useMemo(() => {
        const returnArray: AutocompleteOption[] = []
        if (questionnaire) {
            questionnaire.questionConfiguration.forEach((quc) => {
                const existedIdentifierIndex = returnArray.findIndex(
                    (ac) => ac.identifier === quc.questionDefinitionIdentifier,
                )
                if (existedIdentifierIndex === -1)
                    returnArray.push({
                        identifier: quc.questionDefinitionIdentifier,
                        translation: t(quc.questionDefinitionIdentifier),
                    })
            })
        }
        return returnArray
    }, [questionnaire, t])

    const { loadTranslationIdentifier } = useTranslation()
    const autocomplateCategoriesOptions = React.useMemo(() => {
        return loadTranslationIdentifier('CATEGORY_')
    }, [loadTranslationIdentifier])

    const identifierCategoriesDisable = (categories: CategoryInternal[]): string[] => {
        return categories.map((ca) => ca.translationKey)
    }

    const onClickQuestionSelect = (identifier: string, categoryID: string) => (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ): void => {
        event.preventDefault()
        event.stopPropagation()
        let qa = state.questionAnchor
        let questionid: string | null = identifier
        let categoryId: string | null = categoryID
        let sdq = [...state.selectedDragQuestions]
        if (state.currentCategory !== categoryId) {
            qa = null
            sdq = []
        }
        if (event.ctrlKey) {
            const index = sdq.indexOf(questionid)
            if (index === -1) {
                sdq.push(questionid)
            } else {
                sdq.splice(index, 1)

                if (questionid === qa) {
                    if (sdq.length > 0) {
                        questionid = sdq[sdq.length - 1]
                    } else {
                        questionid = null
                        categoryId = null
                    }
                }
            }
            setState({
                ...state,
                selectedDragQuestions: sdq,
                questionAnchor: questionid,
                currentCategory: categoryId,
                currentlyDragging: null,
            })
        } else if (event.shiftKey && qa !== null) {
            const newCategories = questionnaire ? questionnaire.categories : []
            const categoryQuestionList = getCategoryQuestionList(categoryId, newCategories)
            const questionIndex = categoryQuestionList.indexOf(questionid)
            const anchorIndex = categoryQuestionList.indexOf(qa)
            const minIndex = Math.min(questionIndex, anchorIndex)
            const maxIndex = Math.max(questionIndex, anchorIndex)
            setState({
                ...state,
                selectedDragQuestions: categoryQuestionList.slice(minIndex, maxIndex + 1),
                questionAnchor: qa,
                currentCategory: categoryId,
                currentlyDragging: null,
            })
        } else {
            if (qa === questionid && sdq.length === 1) {
                setState({
                    ...state,
                    selectedDragQuestions: [],
                    questionAnchor: null,
                    currentCategory: null,
                    currentlyDragging: null,
                })
            } else {
                setState({
                    ...state,
                    selectedDragQuestions: [questionid],
                    questionAnchor: questionid,
                    currentCategory: categoryId,
                    currentlyDragging: null,
                })
            }
        }
    }
    const onRemoveQuestion = (index: number, categoryIndex: number): void => {
        questionDeleteHandler({
            variables: {
                input: {
                    categoryIndex,
                    index,
                    questionnaireID,
                },
            },
        })
    }
    const onEditQuestion = (identifier: string): void => {
        if (questionnaire) {
            const questionIndex = questionnaire.questionConfiguration.findIndex(
                (qu) => qu.questionDefinitionIdentifier === identifier,
            )
            if (questionIndex !== -1) {
                setState({
                    ...state,
                    openDialog: DialogType.EDIT_QUESTION,
                    dialogIndex: questionIndex,
                })
            }
        }
    }
    const onDragStart = (initial: DragStart): void => {
        const dragingQuestionId = initial.draggableId
        if (state.selectedDragQuestions.indexOf(dragingQuestionId) === -1) {
            setState({
                ...state,
                selectedDragQuestions: [dragingQuestionId],
                questionAnchor: dragingQuestionId,
                currentCategory: initial.source.droppableId,
                currentlyDragging: dragingQuestionId,
            })
        } else {
            setState({
                ...state,
                currentlyDragging: dragingQuestionId,
            })
        }
    }
    const onMoveCategory = (sourceIndex: number, destinationIndex: number): void => {
        categoryDragDropHandler({
            variables: {
                input: {
                    sourceIndex,
                    destinationIndex,
                    questionnaireID,
                },
            },
        })
    }
    const onMoveQuestion = (
        sourceCategoryId: string,
        targetCategoryId: string,
        sourceIndex: number,
        destinationIndex: number,
    ): void => {
        const newCategories = questionnaire ? questionnaire.categories : []
        let targetIndex = destinationIndex
        const selectedDragQuestions = state.selectedDragQuestions
        let targetCategoryIndex = getCategoryIndex(targetCategoryId, newCategories)
        if (
            sourceCategoryId !== 'uncategorized_questions' &&
            targetCategoryId !== 'uncategorized_questions' &&
            sourceCategoryId === targetCategoryId
        ) {
            const destinationIndexOffset = selectedDragQuestions.reduce((previous, current) => {
                if (current === state.currentlyDragging) {
                    return previous
                }
                const index = newCategories[getCategoryIndex(sourceCategoryId, newCategories)].questions.indexOf(
                    current,
                )
                if (index >= targetIndex) {
                    return previous
                }
                return previous + 1
            }, 0)
            targetIndex = destinationIndex - destinationIndexOffset
        }
        if (
            sourceCategoryId !== 'uncategorized_questions' &&
            targetCategoryId !== 'uncategorized_questions' &&
            sourceCategoryId !== targetCategoryId
        ) {
            targetIndex = destinationIndex
        }
        if (sourceCategoryId !== 'uncategorized_questions' && targetCategoryId === 'uncategorized_questions') {
            targetIndex = destinationIndex
            targetCategoryIndex = -1
        }
        questionMultiAddHandler({
            variables: {
                input: {
                    ids: selectedDragQuestions,
                    index: targetIndex,
                    categoryIndex: targetCategoryIndex,
                    questionnaireID,
                },
            },
        })
        setState({
            ...state,
            selectedDragQuestions: [],
            questionAnchor: null,
            currentCategory: null,
            currentlyDragging: null,
        })
    }
    const onDragEnd = (result: DropResult): void => {
        if (
            !result.destination ||
            (result.destination.droppableId === 'uncategorized_questions' &&
                result.source.droppableId === 'uncategorized_questions') ||
            (result.source.droppableId === result.destination.droppableId &&
                result.source.index === result.destination.index)
        ) {
            setState({
                ...state,
                currentlyDragging: null,
            })
            return
        }
        if (result.type === DragDropTypes.CATEGORY) {
            setState({
                ...state,
                currentlyDragging: null,
            })
            onMoveCategory(result.source.index, result.destination.index)
        } else {
            onMoveQuestion(
                result.source.droppableId,
                result.destination.droppableId,
                result.source.index,
                result.destination.index,
            )
        }
    }
    const onDeleteCategory = (index: number): void => {
        categoryDeleteHandler({
            variables: {
                input: {
                    index,
                    questionnaireID,
                },
            },
        })
    }
    const onDisableToggleCategory = (index: number): void => {
        categoryDisableHandler({
            variables: {
                input: {
                    index,
                    questionnaireID,
                },
            },
        })
    }
    const onSaveQuetion = (question: QuestionConfiguration): void => {
        questionSaveHandler({
            variables: {
                input: {
                    questionnaireID,
                    questionDefinitionIdentifier: question.questionDefinitionIdentifier,
                    answerRequired: question.answerRequired,
                    mediaRequired: question.mediaRequired,
                    questionRequired: question.questionRequired,
                    allowMediaMultiple: question.allowMediaMultiple,
                    conditionsCombination: question.conditionsCombination,
                    conditions: JSON.stringify(question.conditions),
                    destinationList: question.destinationList,
                    disabledOptions: question.disabledOptions,
                    options: question.options,
                },
            },
        })
    }
    const onAddQuestions = (questions: AutocompleteOption[], index: number): void => {
        const ids: string[] = questions.map((qu) => qu.identifier)
        questionMultiAddHandler({
            variables: {
                input: {
                    ids,
                    index: -1,
                    categoryIndex: index,
                    questionnaireID,
                },
            },
        })
        console.log('onAddQuestions default')
    }
    const onCreateAutocomplate = (
        name: AutocompleteOption | null,
        inputName: string,
        type: DialogType,
        index: number,
    ): void => {
        switch (type) {
            case DialogType.CREATE_CATEGORY:
                categoryCreateHandler({
                    variables: {
                        input: {
                            translationKey: inputName === name?.translation ? name.identifier : null,
                            translation: inputName,
                            questionnaireID,
                        },
                    },
                })
                break
            case DialogType.EDIT_CATEGORY:
                categoryEditHandler({
                    variables: {
                        input: {
                            index: index,
                            translationKey: inputName === name?.translation ? name.identifier : null,
                            translation: inputName,
                            questionnaireID,
                        },
                    },
                })
                break
            case DialogType.ADD_QUESTION:
                if (name !== null) {
                    questionReAddHandler({
                        variables: {
                            input: {
                                id: name.identifier,
                                index: 0,
                                categoryIndex: index,
                                questionnaireID,
                            },
                        },
                    })
                }
                break
            default:
                console.log('onCreateAutocomplate default')
        }
        // do something
    }
    const navigationList: NavLink[] = React.useMemo(
        () => [
            {
                key: t('machineTypes'),
                link: '/machineTypes',
            },
            {
                key: t(machineTypeIdentifier),
                link: '/machineType/' + machineTypeIdentifier,
            },
            {
                key: questionnaire ? t(questionnaire.translationKey) : '',
            },
        ],
        [machineTypeIdentifier, questionnaire, t],
    )

    React.useEffect(
        () => {
            getQuestionnaireQuery()
        },
        // eslint-disable-next-line
        [],
    )

    React.useEffect(() => {
        //Global load state
        const isAnythingLoading =
            getQuestionnaireQueryStatus.loading ||
            categoryDeleteHandlerStatus.loading ||
            categoryDisableHandlerStatus.loading ||
            categoryDragDropHandlerStatus.loading ||
            questionSaveHandlerStatus.loading ||
            categoryCreateHandlerStatus.loading ||
            categoryEditHandlerStatus.loading ||
            questionMultiAddHandlerStatus.loading ||
            questionReAddHandlerStatus.loading ||
            questionDeleteHandlerStatus.loading
        if (isAnythingLoading !== loadState.loading) {
            setLoadState({
                ...loadState,
                loading: isAnythingLoading,
            })
        }
    }, [
        getQuestionnaireQueryStatus.loading,
        categoryDeleteHandlerStatus.loading,
        categoryDisableHandlerStatus.loading,
        categoryDragDropHandlerStatus.loading,
        questionSaveHandlerStatus.loading,
        questionMultiAddHandlerStatus.loading,
        categoryCreateHandlerStatus.loading,
        categoryEditHandlerStatus.loading,
        questionReAddHandlerStatus.loading,
        questionDeleteHandlerStatus.loading,
        setLoadState,
        loadState,
    ])
    return {
        autocomplateCategoriesOptions,
        identifierCategoriesDisable,
        loadState,
        navigationList,
        onAddQuestions,
        onClickQuestionSelect,
        onCreateAutocomplate,
        onDeleteCategory,
        onDisableToggleCategory,
        onDragEnd,
        onDragStart,
        onEditQuestion,
        onRemoveQuestion,
        onSaveQuetion,
        questionSourceOptions,
        questionnaire,
        setState,
        state,
        uncategorizedQuestions,
    }
}
