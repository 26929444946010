import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import React from 'react'
import { AutocompleteOption, DialogType } from '../../utils/constants'
import useI18n from '../../utils/testable/useI18n'
import testIds from '../../utils/testIds'
import QuestionCopyAnswer from '../QuestionCopyAnswer'

export interface MultiAutocompleteDialogProps {
    onCLose: () => void
    onCreate: (value: AutocompleteOption[]) => void
    options: AutocompleteOption[]
    open: boolean
    value: AutocompleteOption[]
}

const MultiAutocompleteDialog = (props: MultiAutocompleteDialogProps): JSX.Element => {
    const { t } = useI18n()
    const { onCLose, onCreate, options, open, value } = props
    const [state, setState] = React.useState<AutocompleteOption[]>(value)

    return (
        <Dialog
            disableBackdropClick={false}
            disableEscapeKeyDown={false}
            aria-labelledby={'MultiAutocompleteDialog-dialog-title'}
            open={open}
            maxWidth={'sm'}
            fullWidth={true}
            style={{ height: '75%' }}
        >
            <DialogTitle id={'MultiAutocompleteDialog-dialog-title'}>
                {t('autocompleteDialog.title_' + DialogType.ADD_QUESTION)}
            </DialogTitle>
            <DialogContent style={{ padding: '24px' }} dividers>
                <QuestionCopyAnswer
                    onChange={(v): void => setState(v)}
                    options={options}
                    value={state}
                    label={'autocompleteDialog.label_' + DialogType.ADD_QUESTION}
                />
            </DialogContent>
            <DialogActions>
                <Button data-testid={testIds.AutocompleteDialog.onCLose} onClick={onCLose}>
                    {t('labelCancel')}
                </Button>
                <Button
                    data-testid={testIds.AutocompleteDialog.onSave}
                    onClick={(): void => {
                        onCreate(state)
                        setState([])
                    }}
                >
                    {t('labelSave')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default MultiAutocompleteDialog
