import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import React from 'react'
import { ExecutionResult } from 'react-apollo/Mutation'
import machineTypeDisable from '../../graphql/mutations/machineTypeDisable'
import getMachineTypes from '../../graphql/queries/getMachineTypes'
import { MachineTypeInternal } from '../../graphql/types'
import { LoadState } from '../../utils/constants'

interface MachineTypeListDataReturn {
    loadState: LoadState
    machineTypeDisableHandler: (options?: any | undefined) => Promise<ExecutionResult<any>>
    machineTypes: MachineTypeInternal[]
}
export const useMachineTypeListData = (searchTerm: string | null): MachineTypeListDataReturn => {
    const [machineTypes, setMachineTypes] = React.useState<MachineTypeInternal[]>([])

    const [getMachineTypeSearch, getMachineTypeSearchStatus] = useLazyQuery(getMachineTypes, {
        variables: {
            searchTerm,
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        onCompleted: (data: any) => {
            if (data && data.machineTypes) {
                setMachineTypes(data.machineTypes)
                setLoadState({ ...loadState, loading: false })
            }
        },
    })

    const [machineTypeDisableHandler, machineTypeDisableHandlerStatus] = useMutation(machineTypeDisable, {
        refetchQueries: ['getMachineTypes'],
    })

    const [loadState, setLoadState] = React.useState<LoadState>({
        errors: undefined,
        loading: true,
    })

    React.useEffect(() => {
        setLoadState({
            errors: undefined,
            loading: machineTypeDisableHandlerStatus.loading || getMachineTypeSearchStatus.loading,
        })
    }, [machineTypeDisableHandlerStatus.loading, getMachineTypeSearchStatus.loading, setLoadState])

    React.useEffect(
        () => {
            getMachineTypeSearch()
        },
        // eslint-disable-next-line
        [],
    )

    return { loadState, machineTypeDisableHandler, machineTypes }
}
