import gql from 'graphql-tag'

export default gql`
    mutation disableMachineType($identifier: String!) {
        machineTypeDisable(identifier: $identifier) {
            identifier
            disabled
        }
    }
`
