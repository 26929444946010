import { useEffect } from "react";

export default function useVersionTitle() {
    useEffect(
        () => {
            document.title =
                document.title +
                " - " +
                process.env.REACT_APP_VERSION +
                " build: " +
                process.env.REACT_APP_BUILD_NUMBER;
        },
        [] // never call again
    );
}
