import { FormControl, makeStyles, TextField } from '@material-ui/core'
import { Autocomplete, RenderInputParams } from '@material-ui/lab'
import React from 'react'
import { AutocompleteOption } from '../../utils/constants'
import { filterOptions, getOptionDisabled, getOptionLabel } from '../../utils/testable/autoCompleteFunc'
import testIds from '../../utils/testIds'

const useStyles = makeStyles({
    formControlAutocomplete: {
        width: '100%',
        zIndex: 99,
    },
})

export interface AutocompleteFormProps {
    identifierDisable: string[]
    inputValue: string
    label?: string
    onChange: (value: AutocompleteOption | null) => void
    onInputChange: (inputValue: string) => void
    options: AutocompleteOption[]
    testId?: string
    value: AutocompleteOption | null
    margin?: 'dense' | 'normal' | 'none'
}

const AutocompleteForm = (props: AutocompleteFormProps): JSX.Element => {
    const classes = useStyles()
    const { identifierDisable, inputValue, label, onChange, onInputChange, options, testId, value, margin } = props
    return (
        <FormControl className={classes.formControlAutocomplete} variant={'filled'}>
            <Autocomplete
                data-testid={testIds.AutocompleteForm.autocomplete + (testId ? '_' + testId : '')}
                disableClearable={true}
                autoHighlight={true}
                freeSolo={true}
                value={value}
                options={options}
                inputValue={inputValue}
                getOptionLabel={getOptionLabel}
                filterOptions={filterOptions}
                getOptionDisabled={getOptionDisabled(identifierDisable)}
                onChange={(e: any, newValue: AutocompleteOption | null): void => onChange(newValue)}
                onInputChange={(_, newInputValue): void => onInputChange(newInputValue)}
                renderInput={(params: RenderInputParams): JSX.Element => (
                    <TextField {...params} label={label} fullWidth={true} variant="filled" margin={margin} />
                )}
            />
        </FormControl>
    )
}

export default AutocompleteForm
