import { Integrations } from "@sentry/apm";
import * as Sentry from "@sentry/react";
import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./App";
import "./i18n/i18n";
import "./index.css";
import { Auth0Provider } from "./utils/reactAuth0Wrapper";

// eslint-disable-next-line no-undef
const envConfig = __envConfig;

if (process.env.NODE_ENV === "production") {
    Sentry.init({
        dsn: envConfig.SENTRY_DSN,
        integrations: [new Integrations.Tracing()],
        tracesSampleRate: 1.0,
        environment: "Client " + envConfig.SENTRY_ENV
    });
}

// A function that routes the user to the right place after login
const onRedirectCallback = appState => {
    window.history.replaceState(
        {},
        document.title,
        appState && appState.targetUrl ? appState.targetUrl : window.location.pathname
    );
};

ReactDOM.render(
    <Sentry.ErrorBoundary fallback={() => <div>An error has occurred</div>} showDialog>
        <Auth0Provider
            leeway={70}
            domain={envConfig.AUTH0_DOMAIN}
            client_id={envConfig.AUTH0_CLIENT_ID}
            audience={envConfig.AUTH0_AUDIENCE}
            redirect_uri={process.env.NODE_ENV === "test" ? undefined : window.location.origin}
            onRedirectCallback={process.env.NODE_ENV === "test" ? undefined : onRedirectCallback}
        >
            <App />
        </Auth0Provider>
    </Sentry.ErrorBoundary>,
    document.getElementById("root")
);
