import { Fade, Grid, IconButton, Tooltip, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import Remove from '@material-ui/icons/Remove'
import Dropdown from 'components/Dropdown'
import React from 'react'
import { ConditionConnector, ConditionObjectType, ConditionValue, ConditionVisibility } from '../../graphql/types'
import { AutocompleteOption, ConditionTypeAction } from '../../utils/constants'
import useI18n from '../../utils/testable/useI18n'
import QuestionCondition from '../QuestionCondition'

export interface QuestionConditionListProps {
    conditionChange: (conditions: ConditionObjectType[]) => void
    combinationChange: (conditionsCombination: ConditionConnector) => void
    conditionsCombination?: ConditionConnector | null
    conditions?: ConditionObjectType[] | null
    questionSourceIdentifierDisable: string[]
    questionSourceOptions: AutocompleteOption[]
}

const QuestionConditionList = (props: QuestionConditionListProps): JSX.Element => {
    const { t } = useI18n()
    const {
        conditionChange,
        combinationChange,
        conditionsCombination,
        questionSourceIdentifierDisable,
        questionSourceOptions,
        conditions,
    } = props
    const [openConditions, setOpenConditions] = React.useState<boolean>(
        conditions && conditions.length > 0 ? true : false,
    )

    const onConditionChange = (conditionIndex: number) => (
        actions: ConditionTypeAction,
        value?: ConditionVisibility | ConditionValue | ConditionConnector | string,
    ): void => {
        const newCondition: ConditionObjectType = {
            action: ConditionVisibility.SHOW_IF,
            questionSource: undefined,
            type: ConditionValue.IS,
            conditionValue: '',
        }
        const newConditionValue: ConditionObjectType[] =
            conditions !== null && conditions !== undefined && conditions.length > 0 ? [...conditions] : []
        switch (actions) {
            case ConditionTypeAction.ACTION:
                newConditionValue[conditionIndex].action = value as ConditionVisibility
                conditionChange(newConditionValue)
                break
            case ConditionTypeAction.QUESTION_SOURCE:
                newConditionValue[conditionIndex].questionSource = value as string
                conditionChange(newConditionValue)
                break
            case ConditionTypeAction.DELETE:
                if (conditions && conditions.length === 1) {
                    setOpenConditions(false)
                }
                newConditionValue.splice(conditionIndex, 1)
                conditionChange(newConditionValue)
                break
            case ConditionTypeAction.TYPE:
                newConditionValue[conditionIndex].type = value as ConditionValue
                conditionChange(newConditionValue)
                break
            case ConditionTypeAction.VALUE:
                newConditionValue[conditionIndex].conditionValue = value as string
                conditionChange(newConditionValue)
                break
            case ConditionTypeAction.OPEN:
                if (!!!conditions || conditions.length === 0) {
                    conditionChange([newCondition])
                }
                setOpenConditions(true)
                break
            case ConditionTypeAction.COMBINATION:
                combinationChange(value as ConditionConnector)
                break
            case ConditionTypeAction.ADD:
                newConditionValue.push(newCondition)
                conditionChange(newConditionValue)
                break
            default:
                // ConditionTypeAction.CLOSE
                setOpenConditions(false)
                conditionChange([])
                break
        }
    }
    return (
        <>
            <Grid
                container
                item
                direction={'row'}
                justify={'flex-start'}
                alignItems={'center'}
                spacing={1}
                style={{
                    padding: '16px 0',
                }}
            >
                <Grid item>
                    <Typography variant={'h4'}>{t('condition')}</Typography>
                </Grid>
                <Grid item>
                    <Tooltip
                        title={openConditions ? t('closeConditions') : t('openConditions')}
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        arrow
                        placement={'bottom'}
                    >
                        <IconButton
                            size={'small'}
                            aria-label="add"
                            onClick={(): void => {
                                openConditions
                                    ? onConditionChange(-33)(ConditionTypeAction.CLOSE)
                                    : onConditionChange(-33)(ConditionTypeAction.OPEN)
                            }}
                        >
                            {openConditions ? <Remove /> : <AddIcon />}
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            {openConditions && (
                <Grid container item direction={'row'} alignItems={'center'} justify={'flex-start'}>
                    {conditions &&
                        conditions.map(
                            (c: ConditionObjectType, i: number): JSX.Element => {
                                return (
                                    <React.Fragment key={i}>
                                        <Grid container item direction={'row'} justify={'center'}>
                                            <QuestionCondition
                                                conditionObjectType={c}
                                                identifierDisable={questionSourceIdentifierDisable}
                                                options={questionSourceOptions}
                                                onConditionChange={onConditionChange(i)}
                                                isSecondCondition={conditions.length > 1 && i > 0}
                                            />
                                        </Grid>
                                        {i < conditions.length - 1 && (
                                            <>
                                                <Grid container item xs={4} direction={'row'} />
                                                <Grid container item xs={4} direction={'row'} justify={'space-between'}>
                                                    <Dropdown
                                                        label={t('conditionConnector')}
                                                        options={[ConditionConnector.AND, ConditionConnector.OR]}
                                                        value={conditionsCombination}
                                                        onChange={(
                                                            event: React.ChangeEvent<{
                                                                name?: string | undefined
                                                                value: unknown
                                                            }>,
                                                        ): void =>
                                                            onConditionChange(-33)(
                                                                ConditionTypeAction.COMBINATION,
                                                                event.target.value as ConditionConnector,
                                                            )
                                                        }
                                                        translationKey={'conditionConnector_'}
                                                    />
                                                </Grid>
                                            </>
                                        )}
                                        {conditions.length < 2 &&
                                            i === conditions.length - 1 &&
                                            c.action === ConditionVisibility.MULTIPLY_BY && (
                                                <Grid container item xs={12} direction={'row'} justify={'center'}>
                                                    <Grid item>
                                                        <Tooltip
                                                            title={t('addCondition')}
                                                            TransitionComponent={Fade}
                                                            TransitionProps={{ timeout: 600 }}
                                                            arrow
                                                            placement={'bottom'}
                                                        >
                                                            <IconButton
                                                                size={'small'}
                                                                aria-label="add"
                                                                onClick={(): void =>
                                                                    onConditionChange(-33)(ConditionTypeAction.ADD)
                                                                }
                                                            >
                                                                <AddIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            )}
                                    </React.Fragment>
                                )
                            },
                        )}
                </Grid>
            )}
        </>
    )
}

export default QuestionConditionList
