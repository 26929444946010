import { Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    Droppable,
    DroppableProvided,
    DroppableStateSnapshot,
    DropResult,
} from 'react-beautiful-dnd'
import QuestionOptions from '../QuestionOptions'

const useStyles = makeStyles({
    options: {
        paddingRight: 16,
        paddingBottom: 8,
        paddingLeft: 16,
    },
})

export interface QuestionOptionsListProps {
    options: string[]
    disabledOptions: string[]
    identifier: string
    onChange: (options: string[], disabledOptions: string[]) => void
}

const QuestionOptionsList = (props: QuestionOptionsListProps): JSX.Element | null => {
    const classes = useStyles()
    const { options, identifier, disabledOptions, onChange } = props

    const getListStyle = (isDraggingOver: boolean): React.CSSProperties => ({
        background: isDraggingOver ? '#ddd' : 'transparent',
        borderRadius: 8,
        minHeight: 30,
        margin: '15px 10px 10px 10px',
        padding: '10px 10px',
        transition: 'background 250ms',
    })

    const onDisableClick = (option: string): void => {
        const newDisabledOptions = JSON.parse(JSON.stringify(disabledOptions))
        const optionIndex = newDisabledOptions.indexOf(option)
        if (optionIndex !== -1) {
            newDisabledOptions.splice(optionIndex, 1)
        } else {
            newDisabledOptions.push(option)
        }
        onChange(options, newDisabledOptions)
    }
    const onDragEnd = (result: DropResult): void => {
        const newOptions = JSON.parse(JSON.stringify(options))
        if (result.destination && result.source.index !== result.destination.index) {
            const movedOption = newOptions.splice(result.source.index, 1)
            newOptions.splice(result.destination.index, 0, movedOption[0])
        }
        onChange(newOptions, disabledOptions)
    }

    if (!options || options.length === 0) return null

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Grid container>
                <Droppable type={'QuestionOptionsList'} droppableId={'QuestionOptionsList'}>
                    {(droppableProvided: DroppableProvided, droppableSnapshot: DroppableStateSnapshot): JSX.Element => (
                        <Grid
                            item
                            xs={12}
                            ref={droppableProvided.innerRef}
                            style={getListStyle(droppableSnapshot.isDraggingOver)}
                        >
                            {options.map((op: string, i: number) => {
                                return (
                                    <Draggable key={i + op} draggableId={op} index={i}>
                                        {(provided: DraggableProvided): JSX.Element => (
                                            <Grid
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                xs={12}
                                                item
                                                className={classes.options}
                                                key={op + '_' + i}
                                            >
                                                <QuestionOptions
                                                    identifier={identifier}
                                                    disabled={disabledOptions.indexOf(op) !== -1}
                                                    onDisableClick={onDisableClick}
                                                    option={op}
                                                />
                                            </Grid>
                                        )}
                                    </Draggable>
                                )
                            })}
                            {droppableProvided.placeholder}
                        </Grid>
                    )}
                </Droppable>
            </Grid>
        </DragDropContext>
    )
}

export default QuestionOptionsList
