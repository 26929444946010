import { QuestionnaireInternal, QuestionTypeInternal } from 'graphql/types'
import React, { createContext, useContext } from 'react'

export type ConditionsOptions = {
    getQuestionOptions: (id: string) => string[]
    setQuestionnaireForConditions: (questionnaire: QuestionnaireInternal) => void
}

export const ConditionsOptionsContext = createContext<ConditionsOptions>({
    getQuestionOptions: () => [],
    setQuestionnaireForConditions: () => {
        /* do nothing */
    },
})

export const useConditionsOptions = (): ConditionsOptions => {
    return useContext(ConditionsOptionsContext)
}
export interface ConditionsOptionsWrapperProps {
    children: JSX.Element
}
const ConditionsOptionsWrapper = (props: ConditionsOptionsWrapperProps): JSX.Element => {
    const [questionnaireForConditions, setQuestionnaireForConditions] = React.useState<
        QuestionnaireInternal | undefined
    >(undefined)
    const questionOptions = (id: string): string[] => {
        let returnArray: string[] = []
        if (questionnaireForConditions) {
            const questionIndex = questionnaireForConditions.questionConfiguration.findIndex(
                (conf) => conf.questionDefinition.identifier === id,
            )
            if (questionIndex !== -1) {
                const newType = questionnaireForConditions.questionConfiguration[questionIndex].questionDefinition.type
                if (newType === QuestionTypeInternal.BOOLEAN) returnArray = ['yes', 'no']
                if (newType === QuestionTypeInternal.ENUM || newType === QuestionTypeInternal.ENUMLIST)
                    returnArray = questionnaireForConditions.questionConfiguration[questionIndex].options
            }
        }
        return returnArray
    }
    const getConditionsOptions = (): ConditionsOptions => {
        return {
            getQuestionOptions: questionOptions,
            setQuestionnaireForConditions,
        }
    }

    return (
        <ConditionsOptionsContext.Provider value={getConditionsOptions()}>
            {props.children}
        </ConditionsOptionsContext.Provider>
    )
}

export default ConditionsOptionsWrapper
