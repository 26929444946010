import { Fade, IconButton, Tooltip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import React from 'react'
import useI18n from '../../utils/testable/useI18n'
import testIds from '../../utils/testIds'

export interface AddQuestionButtonProps {
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const AddQuestionButton = (props: AddQuestionButtonProps): JSX.Element => {
    const { onClick } = props
    const { t } = useI18n()

    return (
        <Tooltip
            title={t('addNewQuestion')}
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            arrow
            placement={'bottom'}
        >
            <IconButton data-testid={testIds.AddQuestionButton.onAdd} size={'small'} aria-label="add" onClick={onClick}>
                <AddIcon />
            </IconButton>
        </Tooltip>
    )
}

export default AddQuestionButton
