import {
    Badge,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    Fade,
    Grid,
    IconButton,
    makeStyles,
    Tooltip,
    Typography,
} from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import React from 'react'
import { Link } from 'react-router-dom'
import useI18n from '../../utils/testable/useI18n'
import testIds from '../../utils/testIds'

const useStyles = makeStyles({
    main: {
        height: 185,
    },
    cardActionArea: {
        height: '72%',
    },
    cardArea: {
        height: '100%',
    },
    cardContent: {
        height: 'calc(100% - 32px)',
    },
    formControl: {
        marginTop: 10,
        width: '100%',
    },
    iconButton: {
        color: '#DDD9C3',
    },
    iconButtonMaster: {
        color: '#55A546',
    },
    editBottomButtonRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginLeft: 'auto',
        minWidth: '70%',
    },
    editMain: {
        display: 'flex',
        flexDirection: 'column',
        height: 185,
    },
    editCardContent: {
        height: 'auto',
    },
    title: {
        margin: 4,
    },
})

export interface MachineTypeTileProps {
    disabled: boolean
    deleted: boolean
    identifier: string
    onDisableClick: (identifier: string) => void
}

const MachineTypeTile = (props: MachineTypeTileProps): JSX.Element => {
    const classes = useStyles()
    const { t } = useI18n()

    const { deleted, disabled, identifier, onDisableClick } = props

    return (
        <Card className={classes.main} key={identifier}>
            <Link
                data-testid={testIds.MachineTypeTile.onClick}
                to={{
                    pathname: '/machineType/' + identifier,
                }}
                style={{ textDecoration: 'none', color: 'inherit' }}
            >
                <CardActionArea className={classes.cardActionArea}>
                    <CardContent className={classes.cardArea}>
                        {deleted && (
                            <Tooltip
                                title={t('machineTypeIsDeleted')}
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                arrow
                                placement={'right'}
                            >
                                <Badge color={'secondary'} invisible={false} variant="dot" style={{ width: '100%' }} />
                            </Tooltip>
                        )}
                        <Grid item xs>
                            <Typography variant={'h6'} className={classes.title} gutterBottom noWrap>
                                {t(identifier)}
                            </Typography>
                            <Typography color={'textSecondary'} className={classes.title} noWrap>
                                {identifier}
                            </Typography>
                        </Grid>
                    </CardContent>
                </CardActionArea>
            </Link>
            <CardActions disableSpacing>
                <span style={{ marginLeft: 'auto', flexDirection: 'row' }}>
                    <Tooltip
                        title={disabled ? t('enableMachineType') : t('disableMachineType')}
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        arrow
                        placement={'bottom'}
                    >
                        <IconButton
                            data-testid={testIds.MachineTypeTile.onDisable}
                            className={!disabled ? classes.iconButtonMaster : classes.iconButton}
                            size={'small'}
                            onClick={(): void => onDisableClick(identifier)}
                        >
                            {disabled ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </Tooltip>
                </span>
            </CardActions>
        </Card>
    )
}

export default MachineTypeTile
