import gql from 'graphql-tag'

export default gql`
    mutation editQuestionnaire($input: QuestionnaireEditInput!) {
        questionnaireEdit(input: $input) {
            id
            translationKey
            type
            disabled
        }
    }
`
