import gql from 'graphql-tag'

export default gql`
    mutation disableQuestionnaire($id: String!) {
        questionnaireDisable(id: $id) {
            id
            translationKey
            type
            disabled
        }
    }
`
