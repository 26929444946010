import gql from 'graphql-tag'

export default gql`
    query getQuestionnaire($id: String!) {
        questionnaire(id: $id) {
            id
            categories {
                id
                disabled
                translationKey
                questions
            }
            questionConfiguration {
                questionDefinitionIdentifier
                questionDefinition {
                    identifier
                    type
                    options
                    deleted
                }
                answerRequired
                mediaRequired
                allowMediaMultiple
                questionRequired
                conditionsCombination
                conditions {
                    action
                    questionSource
                    type
                    conditionValue
                }
                destinationList
                disabledOptions
                options
            }
            disabled
            translationKey
            type
            machineTypeIdentifier
        }
    }
`
