import gql from 'graphql-tag'

export default gql`
    query getMachineTypes($searchTerm: String) {
        machineTypes(searchTerm: $searchTerm) {
            identifier
            disabled
            # deleted
        }
    }
`
