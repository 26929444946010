import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Typography,
} from '@material-ui/core'
import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import NewQuestionnaireTile from '../../components/NewQuestionnaireTile'
import QuestionnaireTile from '../../components/QuestionnaireTile'
import SearchBar from '../../components/SearchBar'
import TitlebarMenu from '../../components/TitlebarMenu'
import { QuestionnaireInternal, QuestionnaireType } from '../../graphql/types'
import useI18n from '../../utils/testable/useI18n'
import testIds from '../../utils/testIds'
import { useQuestionnaireListData } from './useQuestionnaireListData'

const QuestionnaireList = (prams: RouteComponentProps<any>): JSX.Element => {
    const { t } = useI18n()
    const searchParams = prams.location && prams.location.search ? prams.location.search : undefined
    const urlParams = new URLSearchParams(searchParams)
    const searchTerm =
        urlParams.get('search') !== undefined && urlParams.get('search') !== null ? urlParams.get('search') : ''
    const machineTypeIdentifier: string = prams.match.params.id
    const {
        autocomplateOptions,
        confirmDelete,
        idToDelete,
        identifierDisable,
        loadState,
        navigationList,
        onCreate,
        onDeleteClick,
        onDuplicateClick,
        onDisableClick,
        onEditClick,
        qIndex,
        questionnaires,
        setIdToDelete,
    } = useQuestionnaireListData(searchTerm, machineTypeIdentifier)

    const questionniareList = questionnaires.filter(
        (qu: QuestionnaireInternal): boolean => qu.type === QuestionnaireType.QUESTIONNAIRE,
    )
    const displyDefinitionList = questionnaires.filter(
        (qu: QuestionnaireInternal): boolean => qu.type === QuestionnaireType.DISPLAY_DEFINITION,
    )

    const onSearch = React.useMemo(
        () => (searchTerm: string): void => {
            const path = '?search=' + searchTerm
            if (prams.history.location.pathname !== path) {
                prams.history.push(path)
            }
        },
        [prams.history],
    )

    const renderTile = React.useCallback(
        (qu: QuestionnaireInternal): JSX.Element => (
            <Grid key={qu.id} item xs={6} md={4} lg={3}>
                <QuestionnaireTile
                    id={qu.id}
                    identifier={qu.translationKey}
                    onDeleteClick={onDeleteClick}
                    disabled={qu.disabled}
                    onDisableClick={onDisableClick}
                    onDuplicateClick={onDuplicateClick}
                    onEditClick={onEditClick(qu.type)}
                    machineTypeIdentifier={machineTypeIdentifier}
                    identifierDisable={identifierDisable(questionnaires)}
                    options={autocomplateOptions}
                />
            </Grid>
        ),
        [
            machineTypeIdentifier,
            onDeleteClick,
            onDisableClick,
            onDuplicateClick,
            onEditClick,
            identifierDisable,
            autocomplateOptions,
            questionnaires,
        ],
    )

    const renderHeader = React.useCallback(
        (): JSX.Element => (
            <Grid container item direction={'row'} xs={12}>
                <Grid item xs={12}>
                    <TitlebarMenu
                        loading={loadState.loading && loadState.errors === undefined}
                        navigationList={navigationList}
                    />
                </Grid>
                <Grid item xs={12}>
                    <SearchBar
                        searchTerm={searchTerm !== null ? searchTerm : ''}
                        searchButtonDisabled={loadState.loading ? true : false}
                        onSearch={onSearch}
                    />
                </Grid>
            </Grid>
        ),
        [loadState.errors, loadState.loading, navigationList, onSearch, searchTerm],
    )

    const renderDialog = React.useCallback(
        (): JSX.Element => (
            <Dialog maxWidth={'xs'} open={idToDelete !== undefined} onClose={(): void => setIdToDelete(undefined)}>
                <DialogTitle>{t('confirmDeleteDialogTitle')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('confirmDeleteDialogContent1')}
                        {t('confirmDeleteDialogContentQuestionnaire')}
                        {"'" + t(questionnaires[qIndex].translationKey) + "'? "}
                        {t('confirmDeleteDialogContent2')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        data-testid={testIds.QuestionnaireList.onDeleteDialogCancel}
                        onClick={(): void => setIdToDelete(undefined)}
                        color="primary"
                    >
                        {t('cancel')}
                    </Button>
                    <Button
                        data-testid={testIds.QuestionnaireList.onDeleteDialogConfirm}
                        onClick={confirmDelete}
                        color="primary"
                        autoFocus
                    >
                        {t('confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        ),
        [confirmDelete, idToDelete, qIndex, questionnaires, setIdToDelete, t],
    )

    return (
        <>
            {qIndex !== -1 && renderDialog()}
            <Grid container direction={'row'} spacing={6}>
                {renderHeader()}
                {loadState.errors === undefined && (
                    <>
                        <Grid container item xs={12}>
                            <Typography variant={'h4'}>{t('questionnaireListTitle')}</Typography>
                        </Grid>
                        <Grid container item xs={12} direction={'row'} spacing={1}>
                            {questionniareList.map(renderTile)}
                            <Grid item xs={6} md={4} lg={3}>
                                <NewQuestionnaireTile
                                    onCreate={onCreate}
                                    identifierDisable={identifierDisable(questionnaires)}
                                    options={autocomplateOptions}
                                    machineTypeIdentifier={machineTypeIdentifier}
                                    type={QuestionnaireType.QUESTIONNAIRE}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                            <Typography variant={'h4'}>{t('displayDefinitions')}</Typography>
                        </Grid>
                        <Grid container item xs={12} direction={'row'} spacing={1}>
                            {displyDefinitionList.map(renderTile)}
                            <Grid item xs={6} md={4} lg={3}>
                                <NewQuestionnaireTile
                                    onCreate={onCreate}
                                    identifierDisable={identifierDisable(questionnaires)}
                                    options={autocomplateOptions}
                                    machineTypeIdentifier={machineTypeIdentifier}
                                    type={QuestionnaireType.DISPLAY_DEFINITION}
                                />
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    )
}

export default QuestionnaireList
