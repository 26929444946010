import { Fade, IconButton, makeStyles, Paper, Tooltip, Typography } from '@material-ui/core'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import React from 'react'
import useI18n from '../../utils/testable/useI18n'
import testIds from '../../utils/testIds'

const useStyles = makeStyles({
    main: {
        padding: '10px 32px 10px 16px',
        position: 'relative',
        userSelect: 'none',
        opacity: 0.8,
    },
    iconButton: {
        position: 'absolute',
        right: '16px',
        top: '14px',
    },
    iconButtonMaster: {
        position: 'absolute',
        right: '16px',
        top: '14px',
        color: '#55A546',
    },
})

export interface QuestionOptionsProps {
    disabled: boolean
    onDisableClick: (option: string) => void
    identifier: string
    option: string
}

const QuestionOptions = (props: QuestionOptionsProps): JSX.Element => {
    const classes = useStyles()
    const { t } = useI18n()
    const [isMouseOver, setIsMouseOver] = React.useState<boolean>(false)
    const { disabled, onDisableClick, option, identifier } = props
    const description = t(identifier + '_' + option + '_DESCRIPTION')

    return (
        <Paper
            className={classes.main}
            onMouseEnter={() => setIsMouseOver(true)}
            onMouseLeave={() => setIsMouseOver(false)}
        >
            <Fade in={isMouseOver} timeout={400}>
                <DragIndicatorIcon style={{ marginRight: 15, marginBottom: -5 }} />
            </Fade>
            <Typography style={{ display: 'inline-block', alignSelf: 'center' }}>{t(option)}</Typography>
            {description !== identifier + '_' + option + '_DESCRIPTION' && (
                <Typography style={{ display: 'inline-block', alignSelf: 'center', marginLeft: 32 }}>
                    {description}
                </Typography>
            )}
            <Tooltip
                title={disabled ? t('enableOption') : t('disableOption')}
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                arrow
                placement={'bottom'}
            >
                <IconButton
                    data-testid={testIds.QuestionOptions.onDisable}
                    className={!disabled ? classes.iconButtonMaster : classes.iconButton}
                    size={'small'}
                    onClick={(): void => onDisableClick(option)}
                >
                    {disabled ? <VisibilityOff /> : <Visibility />}
                </IconButton>
            </Tooltip>
        </Paper>
    )
}

export default QuestionOptions
