import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { useTranslation } from "react-i18next";
import testIds from "../../utils/testIds";

const useStyles = makeStyles({
    dialogContent: {
        padding: "24px"
    }
});

const NetworkErrorDialog = props => {
    const classes = useStyles();
    const { t } = useTranslation();
    let title = "";
    let content = "";
    let isOpen = false;

    if (props.graphQL !== undefined && props.graphQL !== null && props.graphQL.length > 0) {
        isOpen = true;
        title = t("graphQLErrorTitle");
        content = t("graphQLErrorContent");
        content += props.graphQL.join(" ");
    } else if (
        props.networkError !== undefined &&
        props.networkError !== null &&
        props.networkError.trim().length > 0
    ) {
        isOpen = true;
        title = t("networkErrorTitle");
        content = t("networkErrorContent");
    }

    return (
        <Dialog
            disableBackdropClick={false}
            disableEscapeKeyDown={false}
            aria-labelledby="customized-dialog-title"
            open={isOpen}
        >
            <DialogTitle id="customized-dialog-title">{title}</DialogTitle>
            <DialogContent className={classes.dialogContent} dividers>
                <Typography>{content}</Typography>
            </DialogContent>
            <DialogActions>
                <Button data-testid={testIds.NetworkErrorDialog.onOK} onClick={props.onClick} color="primary">
                    {t("ok")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

NetworkErrorDialog.propTypes = {
    onClick: PropTypes.func.isRequired,
    networkError: PropTypes.string,
    graphQL: PropTypes.array
};

export default NetworkErrorDialog;
