import { Grid } from '@material-ui/core'
import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import MachineTypeTile from '../../components/MachineTypeTile'
import SearchBar from '../../components/SearchBar'
import TitlebarMenu from '../../components/TitlebarMenu'
import { MachineTypeInternal } from '../../graphql/types'
import { NavLink } from '../../utils/constants'
import useI18n from '../../utils/testable/useI18n'
import { useMachineTypeListData } from './useMachineTypeListData'

const MachineTypeList = (prams: RouteComponentProps<any>): JSX.Element => {
    const { t } = useI18n()
    const navigationList: NavLink[] = React.useMemo(() => [{ key: t('machineTypes') }], [t])
    const searchParams = prams.location && prams.location.search ? prams.location.search : undefined
    const urlParams = new URLSearchParams(searchParams)
    const searchTerm =
        urlParams.get('search') !== undefined && urlParams.get('search') !== null ? urlParams.get('search') : ''
    const { loadState, machineTypeDisableHandler, machineTypes } = useMachineTypeListData(searchTerm)

    const onSearch = React.useMemo(
        () => (searchTerm: string): void => {
            const path = '?search=' + searchTerm
            if (prams.history.location.pathname !== path) {
                prams.history.push(path)
            }
        },
        [prams.history],
    )

    const onDisableClick = React.useCallback(
        (identifier: string): void => {
            machineTypeDisableHandler({
                variables: { identifier },
            })
        },
        [machineTypeDisableHandler],
    )

    const renderTile = (machineType: MachineTypeInternal): JSX.Element => {
        return (
            <Grid key={machineType.identifier} item xs={6} md={4} lg={3}>
                <MachineTypeTile
                    disabled={machineType.disabled}
                    identifier={machineType.identifier}
                    deleted={machineType.deleted}
                    onDisableClick={onDisableClick}
                />
            </Grid>
        )
    }
    return (
        <Grid container direction={'row'} spacing={6}>
            <Grid container item direction={'row'}>
                <Grid item xs={12}>
                    <TitlebarMenu
                        loading={loadState.loading && loadState.errors === undefined}
                        navigationList={navigationList}
                    />
                </Grid>
                <Grid item xs={12}>
                    <SearchBar
                        searchTerm={searchTerm !== null ? searchTerm : ''}
                        searchButtonDisabled={loadState.loading ? true : false}
                        onSearch={onSearch}
                    />
                </Grid>
            </Grid>
            {loadState.errors === undefined && (
                <Grid container item xs={12} direction={'row'} spacing={1}>
                    {!!machineTypes && machineTypes.length > 0 && machineTypes.map(renderTile)}
                </Grid>
            )}
        </Grid>
    )
}

export default MachineTypeList
