import gql from 'graphql-tag'

export default gql`
    query getQuestionnaires($searchTerm: String, $machineTypeIdentifier: String!) {
        questionnaires(searchTerm: $searchTerm, machineTypeIdentifier: $machineTypeIdentifier) {
            id
            translationKey
            type
            disabled
        }
    }
`
