import { FilterOptionsState } from '@material-ui/lab'
import { AutocompleteOption } from '../constants'

export const filterOptions = (options: AutocompleteOption[], state: FilterOptionsState): AutocompleteOption[] =>
    options.filter(
        (op) =>
            op.identifier.includes(state.inputValue.toUpperCase()) ||
            op.translation.toUpperCase().includes(state.inputValue.toUpperCase()),
    )

export const getOptionLabel = (option: AutocompleteOption): string => option.translation

export const getOptionDisabled = (identifierDisable: string[]) => (option: AutocompleteOption): boolean =>
    identifierDisable.includes(option.identifier)

export const disabledSaveAutocomplete = (
    input: string,
    disableIdentifier: string[],
    autocompleteOptions: AutocompleteOption[],
): boolean => {
    const identifier = autocompleteOptions.find((op) => op.translation === input)
    return input.length < 3
        ? true
        : identifier !== undefined
        ? disableIdentifier.includes(identifier.identifier)
        : false
}
