import {
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    Fade,
    Grid,
    makeStyles,
    Tooltip,
    Typography,
} from '@material-ui/core'
import { Add } from '@material-ui/icons'
import React from 'react'
import { QuestionnaireType } from '../../graphql/types'
import { AutocompleteOption } from '../../utils/constants'
import { disabledSaveAutocomplete } from '../../utils/testable/autoCompleteFunc'
import useI18n from '../../utils/testable/useI18n'
import testIds from '../../utils/testIds'
import AutocompleteForm from '../AutocompleteForm'

const useStyles = makeStyles({
    bottomButtonRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginLeft: 'auto',
        minWidth: '40%',
    },
    cardArea: {
        height: '100%',
    },
    cardContent: {
        alignItems: 'center',
        display: 'flex',
        height: 'calc(100% - 32px)',
        justifyContent: 'center',
    },
    iconStyle: {
        color: 'rgb(238, 236, 225)',
        transform: 'scale(5)',
    },
    main: {
        height: 185,
    },
    mainCardContent: {
        height: 'auto',
    },
})

export interface NewQuestionnaireTileProps {
    identifierDisable: string[]
    machineTypeIdentifier: string
    onCreate: (name: AutocompleteOption | null, inputName: string, type: string, machineTypeIdentifier: string) => void
    options: AutocompleteOption[]
    type: QuestionnaireType
}

interface StateType {
    name: AutocompleteOption | null
    inputName: string
    showForm: boolean
}

const NewQuestionnaireTile = (props: NewQuestionnaireTileProps): JSX.Element => {
    const classes = useStyles()
    const { t } = useI18n()
    const [state, setState] = React.useState<StateType>({
        name: null,
        inputName: '',
        showForm: false,
    })

    const { identifierDisable, machineTypeIdentifier, onCreate, options, type } = props

    return (
        <Card className={classes.main}>
            {!state.showForm && (
                <Tooltip
                    title={
                        type === QuestionnaireType.QUESTIONNAIRE
                            ? t('createQuestionnaire')
                            : t('createDisplayDefinition')
                    }
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 600 }}
                    arrow
                    placement={'bottom'}
                >
                    <CardActionArea
                        data-testid={testIds.NewQuestionnaireTile.onShowForm}
                        className={classes.cardArea}
                        onClick={(): void => setState({ ...state, showForm: true })}
                    >
                        <CardContent className={classes.cardContent}>
                            <Add color={'action'} fontSize="large" className={classes.iconStyle} />
                        </CardContent>
                    </CardActionArea>
                </Tooltip>
            )}
            {state.showForm && (
                <>
                    <CardContent className={classes.mainCardContent}>
                        <Grid item xs>
                            <Typography variant={'h6'} gutterBottom noWrap>
                                {type === QuestionnaireType.QUESTIONNAIRE
                                    ? t('newQuestionnaireTileTitle')
                                    : t('newDisplayDefinitionTitle')}
                            </Typography>
                            <AutocompleteForm
                                testId={'newQuestionnaireTile'}
                                identifierDisable={identifierDisable}
                                inputValue={state.inputName}
                                onChange={(value: AutocompleteOption | null): void =>
                                    setState({ ...state, name: value })
                                }
                                onInputChange={(inputValue: string): void =>
                                    setState({ ...state, inputName: inputValue })
                                }
                                options={options}
                                value={state.name}
                                label={t('newQuestionnaireTileName')}
                            />
                        </Grid>
                    </CardContent>
                    <CardActions disableSpacing>
                        <Grid className={classes.bottomButtonRow}>
                            <Button
                                data-testid={testIds.NewQuestionnaireTile.onCancel}
                                color="primary"
                                onClick={(): void => setState({ name: null, inputName: '', showForm: false })}
                            >
                                <Typography noWrap>{t('labelCancel')}</Typography>
                            </Button>
                            <Button
                                data-testid={testIds.NewQuestionnaireTile.onSave}
                                variant="contained"
                                color="primary"
                                disabled={disabledSaveAutocomplete(state.inputName, identifierDisable, options)}
                                onClick={(): void => {
                                    onCreate(state.name, state.inputName, type, machineTypeIdentifier)
                                    setState({ name: null, inputName: '', showForm: false })
                                }}
                            >
                                <Typography noWrap>{t('labelSave')}</Typography>
                            </Button>
                        </Grid>
                    </CardActions>
                </>
            )}
        </Card>
    )
}

export default NewQuestionnaireTile
