import { Checkbox, FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core'
import { MediaRequiredType } from 'graphql/types'
import React from 'react'
import useI18n from 'utils/testable/useI18n'
import testIds from 'utils/testIds'

export interface QuestionMediaProps {
    allowMediaMultiple?: boolean | null
    mediaRequired: MediaRequiredType
    onMediaRequiredChange: (value: MediaRequiredType) => void
    toggleMediaMultiple: (bool: boolean) => void
}

const QuestionMedia = (props: QuestionMediaProps): JSX.Element => {
    const { t } = useI18n()
    const { allowMediaMultiple, mediaRequired, onMediaRequiredChange, toggleMediaMultiple } = props

    return (
        <Grid container direction={'row'} spacing={2} style={{ padding: '16px 0' }}>
            <Grid container item xs={12}>
                <RadioGroup
                    row={true}
                    value={mediaRequired}
                    onChange={(_, value: string): void => onMediaRequiredChange(value as MediaRequiredType)}
                >
                    <FormControlLabel
                        data-testid={testIds.QuestionMedia.onMediaRequiredImage}
                        value={MediaRequiredType.IMAGE}
                        control={<Radio />}
                        label={t('questionMediaImage')}
                    />
                    <FormControlLabel
                        data-testid={testIds.QuestionMedia.onMediaRequiredVideo}
                        value={MediaRequiredType.VIDEO}
                        control={<Radio />}
                        label={t('questionMediaVideo')}
                    />
                    <FormControlLabel
                        data-testid={testIds.QuestionMedia.onMediaRequiredBoth}
                        value={MediaRequiredType.BOTH}
                        control={<Radio />}
                        label={t('questionMediaBoth')}
                    />
                </RadioGroup>
            </Grid>
            <Grid container item xs={12}>
                <FormControlLabel
                    data-testid={testIds.QuestionMedia.onMediaRequiredAllowMultiple}
                    control={<Checkbox checked={allowMediaMultiple ?? false} />}
                    onChange={(_, checked: boolean): void => toggleMediaMultiple(checked)}
                    label={t('questionMediaMultiple')}
                />
            </Grid>
        </Grid>
    )
}

export default QuestionMedia
