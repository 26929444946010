import { Badge, Fade, IconButton, makeStyles, Paper, Tooltip, Typography } from '@material-ui/core'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import Edit from '@material-ui/icons/Edit'
import LinkIcon from '@material-ui/icons/Link'
import LinkOffIcon from '@material-ui/icons/LinkOff'
import React from 'react'
import useI18n from '../../utils/testable/useI18n'

const useStyles = makeStyles({
    main: {
        padding: '10px 32px 10px 16px',
        position: 'relative',
        userSelect: 'none',
    },
    backgroundIcon: {
        position: 'absolute',
        right: '10px',
        top: '10px',
    },
    icon: {
        position: 'absolute',
        right: '10px',
        top: '10px',
        cursor: 'pointer',
        background: 'white',
        '&:hover': {
            opacity: '0',
        },
    },
    iconEdit: {
        position: 'absolute',
        right: '54px',
        top: '6px',
        cursor: 'pointer',
        background: 'white',
    },
    selected: {
        padding: '10px 32px 10px 16px',
        position: 'relative',
        border: '1px solid #55a546',
        userSelect: 'none',
    },
    selectedDragging: {
        padding: '10px 32px 10px 16px',
        position: 'relative',
        border: '1px solid #55a546',
        opacity: 0.3,
    },
})

export interface QuestionTileProps {
    currentlyDragging: string | null
    identifier: string
    deleted: boolean
    onEdit?: () => void
    onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    onRemovePress: () => void
    removable: boolean
    selected: boolean
}

const QuestionTile = (props: QuestionTileProps): JSX.Element => {
    const classes = useStyles()
    const { t } = useI18n()
    const [isMouseOver, setIsMouseOver] = React.useState(false)
    const { identifier, selected, onEdit, onClick, onRemovePress, currentlyDragging, removable, deleted } = props
    let paperStyle = classes.main

    const description = t(identifier + '_DESCRIPTION')

    if (selected && currentlyDragging === null) {
        paperStyle = classes.selected
    } else if (selected && currentlyDragging !== null && currentlyDragging !== identifier) {
        paperStyle = classes.selectedDragging
    }

    return (
        <Paper
            onClick={onClick}
            className={paperStyle}
            onMouseEnter={() => setIsMouseOver(true)}
            onMouseLeave={() => setIsMouseOver(false)}
        >
            {deleted && (
                <Tooltip
                    title={t('questionIsDeleted')}
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 600 }}
                    arrow
                    placement={'right'}
                >
                    <Badge color={'secondary'} invisible={false} variant="dot" />
                </Tooltip>
            )}
            <Fade in={isMouseOver} timeout={400}>
                <DragIndicatorIcon style={{ marginRight: 15, marginBottom: -5 }} />
            </Fade>
            <Typography style={{ display: 'inline-block', alignSelf: 'center' }}>{t(identifier)}</Typography>
            {onEdit !== undefined && (
                <Tooltip
                    title={t('editQuestion')}
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 600 }}
                    arrow
                    placement={'bottom'}
                >
                    <IconButton
                        className={classes.iconEdit}
                        size={'small'}
                        onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
                            event.preventDefault()
                            event.stopPropagation()
                            onEdit()
                        }}
                    >
                        <Edit />
                    </IconButton>
                </Tooltip>
            )}
            {description !== identifier + '_DESCRIPTION' && (
                <Typography style={{ display: 'inline-block', alignSelf: 'center', marginLeft: 32 }}>
                    {description}
                </Typography>
            )}
            {removable && (
                <>
                    <LinkOffIcon className={classes.backgroundIcon} />
                    <LinkIcon
                        className={classes.icon}
                        onClick={(event: React.MouseEvent<SVGSVGElement, MouseEvent>): void => {
                            event.preventDefault()
                            event.stopPropagation()
                            onRemovePress()
                        }}
                    />
                </>
            )}
        </Paper>
    )
}

export default QuestionTile
