import { ApolloError } from 'apollo-client'

export interface AutocompleteOption {
    identifier: string
    translation: string
}

export interface AutocompleteState {
    name: AutocompleteOption | null
    inputName: string
}

export type EnvConfig = {
    API_URL: string
    AUTH0_AUDIENCE: string
    AUTH0_CLIENT_ID: string
    AUTH0_DOMAIN: string
    EXPORT_URL: string
    SENTRY_ENV: string
    SENTRY_DSN: string
}
export interface ErrorConfig {
    title: string
    content: string
    messages?: string[]
}

export interface LoadState {
    errors?: ApolloError
    loading: boolean
}

export const DragDropTypes = {
    CATEGORY: 'CATEGORY',
    QUESTION: 'QUESTION',
}

export interface NavLink {
    key: string
    link?: string
}

export enum DialogType {
    CREATE_CATEGORY = 'CREATE_CATEGORY',
    ADD_QUESTION = 'ADD_QUESTION',
    EDIT_QUESTION = 'EDIT_QUESTION',
    EDIT_CATEGORY = 'EDIT_CATEGORY',
    DELETE_CATEGORY = 'DELETE_CATEGORY',
}

export enum ConditionTypeAction {
    ACTION = 'ACTION',
    ADD = 'ADD',
    CLOSE = 'CLOSE',
    COMBINATION = 'COMBINATION',
    DELETE = 'DELETE',
    OPEN = 'OPEN',
    QUESTION_SOURCE = 'QUESTION_SOURCE',
    TYPE = 'TYPE',
    VALUE = 'VALUE',
}
