import { Checkbox } from '@material-ui/core'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import { RenderOptionState } from '@material-ui/lab'
import React from 'react'
import { AutocompleteOption } from '../../utils/constants'

export default function RenderOption(option: AutocompleteOption, state: RenderOptionState): JSX.Element {
    return (
        <React.Fragment>
            <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" color={'primary'} />}
                style={{ marginRight: 8 }}
                checked={state.selected}
            />
            {option.translation}
        </React.Fragment>
    )
}
