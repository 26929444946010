import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core'
import QuestionMedia from 'components/QuestionMedia'
import React from 'react'
import {
    ConditionConnector,
    ConditionObjectType,
    MediaRequiredType,
    QuestionConfiguration,
    QuestionTypeInternal,
} from '../../graphql/types'
import { AutocompleteOption } from '../../utils/constants'
import useI18n from '../../utils/testable/useI18n'
import testIds from '../../utils/testIds'
import QuestionConditionList from '../QuestionConditionList'
import QuestionCopyAnswer from '../QuestionCopyAnswer'
import QuestionFooter from '../QuestionFooter'
import QuestionHeader from '../QuestionHeader'
import QuestionOptionsList from '../QuestionOptionsList'

export interface QuestionDialogProps {
    onClose: () => void
    onSave: (question: QuestionConfiguration) => void
    open: boolean
    question: QuestionConfiguration
    questionSourceOptions: AutocompleteOption[]
}

const QuestionDialog = (props: QuestionDialogProps): JSX.Element => {
    const { t } = useI18n()
    const { onClose, onSave, open, question, questionSourceOptions } = props
    const [questionConfig, setQuestionConfig] = React.useState<QuestionConfiguration>(
        JSON.parse(JSON.stringify(question)),
    )
    const onAnswerRequiredToggle = (bool: boolean) => {
        setQuestionConfig({
            ...questionConfig,
            answerRequired: bool,
        })
    }
    const onQuestionRequiredToggle = (bool: boolean) => {
        setQuestionConfig({
            ...questionConfig,
            questionRequired: bool,
        })
    }
    const onMediaRequiredChange = (value: MediaRequiredType) => {
        setQuestionConfig({
            ...questionConfig,
            mediaRequired: value,
        })
    }
    const toggleMediaMultiple = (bool: boolean) => {
        setQuestionConfig({
            ...questionConfig,
            allowMediaMultiple: bool,
        })
    }
    const conditionChange = (value: ConditionObjectType[]) => {
        setQuestionConfig({
            ...questionConfig,
            conditions: value,
        })
    }
    const combinationChange = (value: ConditionConnector) => {
        setQuestionConfig({
            ...questionConfig,
            conditionsCombination: value,
        })
    }
    const copyAnswerChange = (value: AutocompleteOption[]) => {
        const newDestinationList = value && value.length > 0 ? value.map((ac) => ac.identifier) : []
        setQuestionConfig({
            ...questionConfig,
            destinationList: newDestinationList,
        })
    }
    const QuestionOptionsListChange = (options: string[], disabledOptions: string[]) => {
        setQuestionConfig({
            ...questionConfig,
            options,
            disabledOptions,
        })
    }
    const questionCopyAnswerValue = React.useMemo(() => {
        return questionConfig.destinationList.map((str) => ({
            identifier: str,
            translation: t(str),
        }))
    }, [questionConfig.destinationList, t])

    return (
        <Dialog
            disableBackdropClick={false}
            disableEscapeKeyDown={false}
            aria-labelledby={'question-dialog-title'}
            open={open}
            maxWidth={'md'}
            fullWidth={true}
            scroll={'paper'}
            style={{ height: '95%' }}
        >
            <DialogTitle id={'question-dialog-title'}>{t(questionConfig.questionDefinitionIdentifier)}</DialogTitle>
            <DialogContent>
                <Grid container direction={'column'}>
                    <Grid item>
                        <QuestionHeader
                            identifier={questionConfig.questionDefinitionIdentifier}
                            type={questionConfig.questionDefinition.type}
                        />
                        {question.questionDefinition.type === QuestionTypeInternal.MEDIA ? (
                            <QuestionMedia
                                allowMediaMultiple={questionConfig.allowMediaMultiple}
                                mediaRequired={questionConfig.mediaRequired}
                                onMediaRequiredChange={onMediaRequiredChange}
                                toggleMediaMultiple={toggleMediaMultiple}
                            />
                        ) : (
                            <QuestionOptionsList
                                disabledOptions={questionConfig.disabledOptions}
                                onChange={QuestionOptionsListChange}
                                identifier={questionConfig.questionDefinitionIdentifier}
                                options={questionConfig.options}
                            />
                        )}

                        <QuestionCopyAnswer
                            onChange={copyAnswerChange}
                            options={questionSourceOptions}
                            value={questionCopyAnswerValue}
                            label={'questionCopyAnswerLabel'}
                        />
                        <QuestionFooter
                            // mediaRequired={questionConfig.mediaRequired}
                            answerRequired={questionConfig.answerRequired}
                            onAnswerRequiredToggle={onAnswerRequiredToggle}
                            // onMediaRequiredChange={onMediaRequiredChange}
                            onQuestionRequiredToggle={onQuestionRequiredToggle}
                            questionRequired={questionConfig.questionRequired}
                        />
                        <QuestionConditionList
                            conditionChange={conditionChange}
                            combinationChange={combinationChange}
                            conditionsCombination={questionConfig.conditionsCombination}
                            conditions={questionConfig.conditions ? questionConfig.conditions : []}
                            questionSourceIdentifierDisable={[]}
                            questionSourceOptions={questionSourceOptions}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button data-testid={testIds.QuestionDialog.onCLose} onClick={onClose}>
                    {t('labelCancel')}
                </Button>
                <Button
                    color={'primary'}
                    data-testid={testIds.QuestionDialog.onSave}
                    onClick={(): void => {
                        onSave(questionConfig)
                    }}
                >
                    {t('labelSave')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default QuestionDialog
