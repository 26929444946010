import gql from 'graphql-tag'

export default gql`
    mutation createQuestionnaire($input: QuestionnaireCreateInput!) {
        questionnaireCreate(input: $input) {
            id
            translationKey
            type
            disabled
        }
    }
`
