const testIds = {
    AddQuestionButton: {
        onAdd: 'AddQuestionButton_onAdd',
    },
    AutocompleteDialog: {
        onCLose: 'AutocompleteDialog_onCLose',
        onSave: 'AutocompleteDialog_onSave',
    },
    AutocompleteForm: {
        autocomplete: 'AutocompleteForm_autocomplete',
    },
    CategoryCreationButton: {
        onAdd: 'CategoryCreationButton_onAdd',
    },
    MachineTypeTile: {
        onClick: 'MachineTypeTile_onClick',
        onDelete: 'MachineTypeTile_onDelete',
        onDisable: 'MachineTypeTile_onDisable',
        onDuplicate: 'MachineTypeTile_onDuplicate',
        onEdit: 'MachineTypeTile_onEdit',
        onEditCancel: 'MachineTypeTile_onEditCancel',
        onEditSave: 'MachineTypeTile_onEditSave',
        onEditTextField: 'MachineTypeTile_onEditTextField',
        onTileMenuDelete: 'MachineTypeTile_onTileMenuDelete',
        onTileMenuDisable: 'MachineTypeTile_onTileMenuDisable',
        onTileMenuDuplicate: 'MachineTypeTile_onTileMenuDuplicate',
        onTileMenuPermission: 'MachineTypeTile_onTileMenuPermission',
    },
    NewLanguageTile: {
        onShowForm: 'NewLanguageTile_onShowForm',
        onCancel: 'NewLanguageTile_onCancel',
        onConfirm: 'NewLanguageTile_onConfirm',
        onChange: 'NewLanguageTile_onChange',
    },
    NewMachineTypeTile: {
        onShowForm: 'NewMachineTypeTile_onShowForm',
        onCancel: 'NewMachineTypeTile_onCancel',
        onSave: 'NewMachineTypeTile_onSave',
        onChange: 'NewMachineTypeTile_onChange',
    },
    NewQuestionnaireTile: {
        onShowForm: 'NewQuestionnaireTile_onShowForm',
        onCancel: 'NewQuestionnaireTile_onCancel',
        onSave: 'NewQuestionnaireTile_onSave',
        onTextFieldChange: 'NewQuestionnaireTile_onTextFieldChange',
        onRadioGroupChange: 'NewQuestionnaireTile_onRadioGroupChange',
    },
    QuestionCopyAnswer: {
        autocomplete: 'QuestionCopyAnswer_autocomplete',
    },
    QuestionDialog: {
        onCLose: 'QuestionDialog_onCLose',
        onSave: 'QuestionDialog_onSave',
    },
    QuestionFooter: {
        onIdentifierChange: 'QuestionFooter_onIdentifierChange',
        onAnswerRequiredCheck: 'QuestionFooter_onAnswerRequiredCheck',
        onQuestionOpenCheck: 'QuestionFooter_onQuestionOpenCheck',
        onQuestionRequiredCheck: 'QuestionFooter_onQuestionRequiredCheck',
        onStatusSelect: 'QuestionFooter_onStatusSelect',
    },
    QuestionMedia: {
        onMediaRequiredImage: 'QuestionMedia_onMediaRequiredImage',
        onMediaRequiredVideo: 'QuestionMedia_onMediaRequiredVideo',
        onMediaRequiredBoth: 'QuestionMedia_onMediaRequiredBoth',
        onMediaRequiredAllowMultiple: 'QuestionMedia_onMediaRequiredAllowMultiple',
    },
    SearchBar: {
        onSearchButton: 'SearchBar_onSearch',
        onSearchTextField: 'SearchBar_onSearchChange',
    },
    QuestionTile: {
        onQuestionCompactClick: 'QuestionTile_onQuestionCompactClick',
    },
    TitlebarMenu: {
        onLinkClick: 'TitlebarMenu_onClickLink',
        text: 'TitlebarMenu_text',
        onLogoutClick: 'TitlebarMenu_onLogoutClick',
    },
    CategoryTile: {
        onCategoryDelete: 'CategoryTile_onCategoryDelete',
        onCategoryDisable: 'CategoryTile_onCategoryDisable',
        onCategorySelect: 'CategoryTile_onCategorySelect',
    },
    CurrentCategoryTile: {
        onChange: 'CurrentCategoryTile_onChange',
    },
    LanguageList: {
        onDeleteDialogCancel: 'LanguageList_onDeleteDialogCancel',
        onDeleteDialogConfirm: 'LanguageList_onDeleteDialogConfirm',
    },
    LanguageTile: {
        onDelete: 'LanguageTile_onDelete',
        onDisable: 'LanguageTile_onDisable',
        onDuplicateFormOpen: 'LanguageTile_onDuplicateFormOpen',
        onDuplicateCancel: 'LanguageTile_onDuplicateCancel',
        onDuplicateConfirm: 'LanguageTile_onDuplicateConfirm',
        onDuplicateChange: 'LanguageTile_onDuplicateChange',
        onEditFormOpen: 'LanguageTile_onEditFormOpen',
        onEditCancel: 'LanguageTile_onEditCancel',
        onEditConfirm: 'LanguageTile_onEditConfirm',
        onEditChange: 'LanguageTile_onEditChange',
        onExportURL: 'LanguageTile_onExportURL',
        onImportFormOpen: 'LanguageTile_onImportFormOpen',
        onImportCancel: 'LanguageTile_onImportCancel',
        onImportCheck: 'LanguageTile_onImportCheck',
        onImportOK: 'LanguageTile_onImportOK',
        onImportSummaryCancel: 'LanguageTile_onImportSummaryCancel',
        onImportSummaryResult: 'LanguageTile_onImportSummaryResult',
        onRadioGroupChange: 'LanguageTile_onRadioGroupChange',
        openFileSelectorButton: 'LanguageTile_openFileSelectorButton',
        openFileSelectorInput: 'LanguageTile_openFileSelectorInput',
        title: 'LanguageTitle_title',
    },
    MachineTypeList: {
        onDeleteDialogCancel: 'MachineTypeList_onDeleteDialogCancel',
        onDeleteDialogConfirm: 'MachineTypeList_onDeleteDialogConfirm',
    },
    NetworkErrorDialog: {
        onOK: 'NetworkErrorDialog_onOK',
    },
    NewCategoryTile: {
        onShowForm: 'NewCategoryTile_onShowForm',
        onCancel: 'NewCategoryTile_onCancel',
        onConfirm: 'NewCategoryTile_onConfirm',
        onChange: 'NewCategoryTile_onChange',
    },
    QuestionBody: {
        onAutoCompleteTextFieldChange: 'QuestionBody_onAutoCompleteTextFieldChange',
        onDateTimeStampCheck: 'QuestionBody_onDateTimeStampCheck',
        onFileUploadCheck: 'QuestionBody_onFileUploadCheck',
        onImageVideoAddIconCheck: 'QuestionBody_onImageVideoAddIconCheck',
        onMultiImageVideoCheck: 'QuestionBody_onMultiImageVideoCheck',
        onLocationCheck: 'QuestionBody_onLocationCheck',
        onDropdownAdd: 'QuestionBody_onDropdownAdd',
        onDestinationAdd: 'QuestionBody_onDestinationAdd',
        onDropdownRadioGroupChange: 'QuestionBody_onDropdownRadioGroupChange',
        onDropdownTextAddOptions: 'QuestionBody_onDropdownTextAddOptions',
        onDropdownImportChange: 'QuestionBody_onDropdownImportChange',
        onOptionAdd: 'QuestionBody_onOptionAdd',
        onDatePickerSelect: 'QuestionBody_onDatePickerSelect',
        onRemoveDestinationClick: 'QuestionBodyTextShort_onRemoveDestinationClick',
        onDeleteDestinationClick: 'QuestionBodyTextShort_onDeleteSourceClick',
        onOpenSearchClick: 'QuestionBodyTextShort_onOpenSearchClick',
        onOpenSearchIconClick: 'QuestionBodyTextShort_onOpenSearchIconClick',
        onCopyAnswerSelect: 'QuestionBodyTextShort_onCopyAnswerSelect',
    },
    QuestionBodyTextShort: {
        onNumberOnlyCheck: 'QuestionBodyTextShort_onNumberOnlyCheck',
    },
    QuestionCondition: {
        onConditionDeleteClick: 'QuestionCondition_onConditionDeleteClick',
        onConditionOpenSearchClick: 'QuestionCondition_onConditionOpenSearchClick',
        onConditionOpenSearchIconClick: 'QuestionCondition_onConditionOpenSearchIconClick',
        onConditionChange: 'QuestionCondition_onConditionChange',
        onConditionTypeChange: 'QuestionCondition_onConditionTypeChange',
        onConditionActionChange: 'QuestionCondition_onConditionActionChange',
    },
    QuestionHeader: {
        onDescriptionChange: 'QuestionHeader_onDescriptionChange',
        onTranslatedDescriptionChange: 'QuestionHeader_onTranslatedDescriptionChange',
        onQuestionChange: 'QuestionHeader_onQuestionChange',
        onTranslatedQuestionChange: 'QuestionHeader_onTranslatedQuestionChange',
        onTypeChange: 'QuestionHeader_onTypeChange',
        onDelete: 'QuestionHeader_onDelete',
        onDisable: 'QuestionHeader_onDisable',
        onDuplicate: 'QuestionHeader_onDuplicate',
        onMove: 'QuestionHeader_onMove',
        onPdfDisable: 'QuestionHeader_onPdfDisable',
    },
    QuestionOptions: {
        onDisable: 'QuestionOptions_onDisable',
        onDescriptionChange: 'QuestionOptions_onChangeDescriptionTextField',
        onTranslatedDescriptionChange: 'QuestionOptions_onTranslatedDescriptionChange',
        onOptionChange: 'QuestionOptions_onOptionChange',
        onTranslatedOptionChange: 'QuestionOptions_onTranslatedOptionChange',
        onOptionDelete: 'QuestionOptions_onOptionDelete',
    },
    QuestionnaireDetail: {
        onAddExistingQuestion: 'QuestionnaireDetail_onAddExistingQuestion',
        onAddNewQuestion: 'QuestionnaireDetail_onAddNewQuestion',
        onDeleteDialogCancel: 'QuestionnaireDetail_onDeleteDialogCancel',
        onDeleteDialogConfirm: 'QuestionnaireDetail_onDeleteDialogConfirm',
        onQuestionMoveCancel: 'QuestionnaireDetail_onQuestionMoveCancel',
        onQuestionMoveConfirm: 'QuestionnaireDetail_onQuestionMoveConfirm',
        onQuestionMoveDialogToggle: 'QuestionnaireDetail_onQuestionMoveDialogToggle',
    },
    QuestionnaireList: {
        onDeleteDialogCancel: 'QuestionnaireList_onDeleteDialogCancel',
        onDeleteDialogConfirm: 'QuestionnaireList_onDeleteDialogConfirm',
    },
    QuestionnaireTile: {
        onClick: 'QuestionnaireTile_onClick',
        onDelete: 'QuestionnaireTile_onDelete',
        onDisable: 'QuestionnaireTile_onDisable',
        onDuplicate: 'QuestionnaireTile_onDuplicate',
        onEditCancel: 'QuestionnaireTile_onEditCancel',
        onEditChange: 'QuestionnaireTile_onEditChange',
        onEditConfirm: 'QuestionnaireTile_onEditConfirm',
        onEditShowForm: 'QuestionnaireTile_onEditShowForm',
        onLanguageClick: 'QuestionnaireTile_onLanguageClick',
        onRadioGroupChange: 'QuestionnaireTile_onRadioGroupChange',
        onTileMenuDelete: 'QuestionnaireTile_onTileMenuDelete',
        onTileMenuDisable: 'QuestionnaireTile_onTileMenuDisable',
        onTileMenuDuplicate: 'QuestionnaireTileonTileMenuDuplicate',
        onTileMenuPermission: 'QuestionnaireTile_onTileMenuPermission',
    },
    SearchPopUp: {
        onSearchChange: 'SearchPopUp_onSearchChange',
        onCloseDialogIconClick: 'SearchPopUp_onCloseDialogIconClick',
        onSearchResultEntryClick: 'SearchPopUp_onSearchResultEntryClick',
        onSearchIconClick: 'SearchPopUp_onSearchIconClick',
    },
    SwitchBar: {
        onAddLanguageClick: 'SwitchBar_onAddLanguageClick',
        onLanguageClick: 'SwitchBar_onLanguageClick',
        onMasterLanguageClick: 'SwitchBar_onMasterLanguageClick',
        onPDFClick: 'SwitchBar_onPDFClick',
    },
    TileMenu: {
        onOpen: 'TileMenu_onOpen',
    },
}
export default testIds
