import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import questionnaireDuplicate from 'graphql/mutations/questionnaireDuplicate'
import _ from 'lodash'
import React from 'react'
import questionnaireCreate from '../../graphql/mutations/questionnaireCreate'
import questionnaireDelete from '../../graphql/mutations/questionnaireDelete'
import questionnaireDisable from '../../graphql/mutations/questionnaireDisable'
import questionnaireEdit from '../../graphql/mutations/questionnaireEdit'
import getQuestionnaires from '../../graphql/queries/getQuestionnaires'
import { QuestionnaireInternal } from '../../graphql/types'
import { AutocompleteOption, LoadState, NavLink } from '../../utils/constants'
import useI18n from '../../utils/testable/useI18n'
import { useTranslation } from '../../utils/TranslationWrapper'

interface QuestionnaireListDataReturn {
    identifierDisable: (questionnaireInternal: QuestionnaireInternal[]) => string[]
    navigationList: NavLink[]
    onDeleteClick: (id: string) => void
    onDisableClick: (id: string) => void
    onDuplicateClick: (id: string) => void
    confirmDelete: () => void
    onEditClick: (type: string) => (name: AutocompleteOption | null, inputName: string, id: string) => void
    onCreate: (name: AutocompleteOption | null, inputName: string, type: string, machineTypeIdentifier: string) => void
    autocomplateOptions: AutocompleteOption[]
    idToDelete: string | undefined
    loadState: LoadState
    qIndex: number
    questionnaires: QuestionnaireInternal[]
    setIdToDelete: React.Dispatch<React.SetStateAction<string | undefined>>
}

export const useQuestionnaireListData = (
    searchTerm: string | null,
    machineTypeIdentifier: string,
): QuestionnaireListDataReturn => {
    const { t } = useI18n()
    const [idToDelete, setIdToDelete] = React.useState<string | undefined>(undefined)
    const machineTypeName = t(machineTypeIdentifier)
    const [qIndex, setQIndex] = React.useState<number>(-1)
    const [questionnaires, setQuestionnaires] = React.useState<QuestionnaireInternal[]>([])
    const [loadState, setLoadState] = React.useState<LoadState>({
        loading: true,
        errors: undefined,
    })

    const [questionnaireSearch, questionnaireSearchStatus] = useLazyQuery(getQuestionnaires, {
        variables: {
            searchTerm: searchTerm,
            machineTypeIdentifier: machineTypeIdentifier,
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        onCompleted: (data: any) => {
            if (data && data.questionnaires) {
                setQuestionnaires(data.questionnaires)
                setLoadState({ ...loadState, loading: false })
            }
        },
    })

    const [questionnaireDeleteHandler, questionnaireDeleteHandlerStatus] = useMutation(questionnaireDelete, {
        refetchQueries: ['getQuestionnaires'],
    })
    const [questionnaireDisableHandler, questionnaireDisableHandlerStatus] = useMutation(questionnaireDisable, {
        refetchQueries: ['getQuestionnaires'],
    })
    const [questionnaireCreateHandler, questionnaireCreateHandlerStatus] = useMutation(questionnaireCreate, {
        refetchQueries: ['getQuestionnaires', 'loadTranslations'],
    })
    const [questionnaireEditHandler, questionnaireEditHandlerStatus] = useMutation(questionnaireEdit, {
        refetchQueries: ['getQuestionnaires', 'loadTranslations'],
    })
    const [questionnaireDuplicateHandler, questionnaireDuplicateHandlerStatus] = useMutation(questionnaireDuplicate, {
        refetchQueries: ['getQuestionnaires'],
    })

    const { loadTranslationIdentifier } = useTranslation()
    const autocomplateOptions = React.useMemo(() => {
        return loadTranslationIdentifier('QUESTIONNAIRE_')
    }, [loadTranslationIdentifier])
    const identifierDisable = (questionnaireInternal: QuestionnaireInternal[]): string[] => {
        return questionnaireInternal.map((qu) => qu.translationKey)
    }
    const navigationList: NavLink[] = React.useMemo(
        () => [
            {
                key: t('machineTypes'),
                link: '/machineTypes',
            },
            {
                key: machineTypeName,
            },
            {
                key: t('questionnaireListTitle'),
            },
        ],
        [machineTypeName, t],
    )

    const onDeleteClick = React.useCallback(
        (id: string): void => {
            setIdToDelete(id)
        },
        [setIdToDelete],
    )
    const onDisableClick = React.useCallback(
        (id: string): void => {
            questionnaireDisableHandler({
                variables: { id },
            })
        },
        [questionnaireDisableHandler],
    )
    const onDuplicateClick = React.useCallback(
        (id) => {
            questionnaireDuplicateHandler({
                variables: { id },
            })
        },
        [questionnaireDuplicateHandler],
    )
    const confirmDelete = React.useCallback((): void => {
        questionnaireDeleteHandler({
            variables: { id: idToDelete },
        })
        setIdToDelete(undefined)
    }, [idToDelete, questionnaireDeleteHandler, setIdToDelete])

    const onEditClick = React.useCallback(
        (type: string) => (name: AutocompleteOption | null, inputName: string, id: string): void => {
            questionnaireEditHandler({
                variables: {
                    input: {
                        id,
                        type,
                        translationKey: name !== null ? name.identifier : undefined,
                        translation: inputName,
                    },
                },
            })
        },
        [questionnaireEditHandler],
    )

    const onCreate = React.useCallback(
        (name: AutocompleteOption | null, inputName: string, type: string, machineTypeIdentifier: string): void => {
            questionnaireCreateHandler({
                variables: {
                    input: {
                        translationKey: name !== null ? name.identifier : undefined,
                        translation: inputName,
                        type,
                        machineTypeIdentifier,
                    },
                },
            })
        },
        [questionnaireCreateHandler],
    )

    React.useEffect(() => {
        const newLoadState = {
            loading:
                questionnaireSearchStatus.loading ||
                questionnaireDeleteHandlerStatus.loading ||
                questionnaireDuplicateHandlerStatus.loading ||
                questionnaireDisableHandlerStatus.loading ||
                questionnaireEditHandlerStatus.loading ||
                questionnaireCreateHandlerStatus.loading,
            errors: loadState.errors,
        }
        if (loadState.loading !== newLoadState.loading) {
            setLoadState(newLoadState)
        } else {
            return
        }
    }, [
        loadState,
        questionnaireCreateHandlerStatus.loading,
        questionnaireDeleteHandlerStatus.loading,
        questionnaireDuplicateHandlerStatus.loading,
        questionnaireDisableHandlerStatus.loading,
        questionnaireEditHandlerStatus.loading,
        questionnaireSearchStatus.loading,
        setLoadState,
    ])

    React.useEffect(() => {
        if (idToDelete !== undefined) {
            if (questionnaires.length > 0) {
                const qIndex = _.findIndex(questionnaires, ['id', idToDelete])
                setQIndex(qIndex)
            }
        } else {
            setQIndex(-1)
        }
        return
    }, [idToDelete, questionnaires])

    React.useEffect(
        () => {
            questionnaireSearch()
        },
        // eslint-disable-next-line
        [],
    )

    return {
        autocomplateOptions,
        confirmDelete,
        idToDelete,
        identifierDisable,
        loadState,
        navigationList,
        onCreate,
        onDeleteClick,
        onDuplicateClick,
        onDisableClick,
        onEditClick,
        qIndex,
        questionnaires,
        setIdToDelete,
    }
}
