import { ApolloProvider } from '@apollo/react-hooks'
import { Container } from '@material-ui/core'
import { MuiThemeProvider } from '@material-ui/core/styles'
import * as Sentry from '@sentry/react'
import { InMemoryCache } from 'apollo-cache-inmemory'
import ApolloClient from 'apollo-client'
import { setContext } from 'apollo-link-context'
import { HttpLink } from 'apollo-link-http'
import React from 'react'
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom'
import NetworkErrorDialog from './components/networkErrorDialog/NetworkErrorDialog'
import { verify as verifyIdToken } from './jwt'
import theme from './theme'
import PrivateRoute from './utils/PrivateRoute'
import { useAuth0 } from './utils/reactAuth0Wrapper'
import { TranslationWrapper } from './utils/TranslationWrapper'
import ConditionsOptionsWrapper from './utils/useConditionsOptions'
import useVersionTitle from './utils/useVersionTitle'
import MachineTypeList from './views/MachineTypeList'
import QuestionnaireDetail from './views/QuestionnaireDetail'
import QuestionnaireList from './views/QuestionnaireList'

// eslint-disable-next-line no-undef
const envConfig = __envConfig

const App = () => {
    const { getTokenSilently, handleOAuth2TokenResponse } = useAuth0()
    const [netErr, setNetErr] = React.useState('')
    const [graErr, setGraErr] = React.useState([])
    useVersionTitle()

    const cache = new InMemoryCache()
    const link = new HttpLink({
        uri: envConfig.API_URL,
    })

    const authLink = setContext(async (_, { headers }) => {
        // get the authentication token from if it exists
        console.log('await getTokenSilently from apollo context')

        const accessToken = await getTokenSilently()

        // return the headers to the context so httpLink can read them
        if (accessToken) {
            return {
                headers: {
                    ...headers,
                    authorization: accessToken ? `Bearer ${accessToken}` : '',
                },
            }
        } else {
            return headers
        }
    })

    const checkAuthentication = async (props) => {
        try {
            console.log('checkAuthentication')
            var searchParams = new URLSearchParams(props.location.search)

            const createRandomString = () => {
                const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-_~.'
                let random = ''
                const randomValues = Array.from(crypto.getRandomValues(new Uint8Array(43)))
                randomValues.forEach((v) => (random += charset[v % charset.length]))
                return random
            }
            const nonceIn = createRandomString()
            const decodedToken = verifyIdToken({
                iss: `https://${envConfig.AUTH0_DOMAIN}/`,
                aud: envConfig.AUTH0_CLIENT_ID,
                id_token: searchParams.get('id_token'),
                nonce: nonceIn,
                leeway: 60,
            })
            const cacheEntry = {
                access_token: searchParams.get('access_token'),
                id_token: searchParams.get('id_token'),
                scope: 'openid profile email',
                expires_in: 86400,
                audience: envConfig.AUTH0_AUDIENCE,
                decodedToken: decodedToken,
            }
            const worked = await handleOAuth2TokenResponse(cacheEntry)
            console.log('handleOAuth2TokenResponse finished')
            if (worked) {
                console.log('handleOAuth2TokenResponse worked, redirecting')
                props.history.replace('/')
            }
        } catch (error) {
            console.log('an error occured checking authentication')
            console.error(error)
        }
    }
    const apolloClient = new ApolloClient({
        cache,
        link: authLink.concat(link),
        onError: ({ graphQLErrors, networkError }) => {
            if (graphQLErrors) {
                Sentry.captureException(graphQLErrors)
                let auxErr = []
                auxErr = graphQLErrors.map(({ message, locations, path }) => {
                    console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
                    return message
                })
                setGraErr(auxErr)
            } else if (networkError) {
                Sentry.captureException(networkError)
                console.log(`[Network error]: ${networkError}`)
                setNetErr(networkError.message)
            }
        },
    })

    return (
        <MuiThemeProvider theme={theme}>
            <ApolloProvider client={apolloClient}>
                <Container maxWidth="lg">
                    <TranslationWrapper>
                        <ConditionsOptionsWrapper>
                            <NetworkErrorDialog
                                onClick={() => {
                                    setNetErr('')
                                    setGraErr([])
                                }}
                                networkError={netErr}
                                graphQL={graErr}
                            />
                            <HashRouter>
                                <Switch>
                                    <Route
                                        path="/callback"
                                        render={(props) => {
                                            checkAuthentication(props)
                                            return <h1>checking auth0</h1>
                                        }}
                                    />
                                    <PrivateRoute exact={true} path="/machineTypes" component={MachineTypeList} />
                                    <PrivateRoute exact={true} path="/machineType/:id" component={QuestionnaireList} />
                                    <PrivateRoute
                                        exact={true}
                                        path="/machineType/:id/questionnaire/:qid"
                                        component={QuestionnaireDetail}
                                    />
                                    <Redirect from="/" to="/machineTypes" />
                                </Switch>
                            </HashRouter>
                        </ConditionsOptionsWrapper>
                    </TranslationWrapper>
                </Container>
            </ApolloProvider>
        </MuiThemeProvider>
    )
}

export default App
