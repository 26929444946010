import { Grid, TextField } from '@material-ui/core'
import React from 'react'
import { QuestionTypeInternal } from '../../graphql/types'
import useI18n from '../../utils/testable/useI18n'
import testIds from '../../utils/testIds'

export interface QuestionHeaderProps {
    identifier: string
    type: QuestionTypeInternal
}

const QuestionHeader = (props: QuestionHeaderProps): JSX.Element => {
    const { t } = useI18n()
    const { identifier, type } = props
    const description = t(identifier + '_DESCRIPTION')

    return (
        <Grid container item direction={'row'} alignContent={'center'} spacing={1}>
            <Grid container item xs={12} sm={7} alignContent={'flex-end'} style={{ marginBottom: 8 }}>
                <Grid item xs>
                    <TextField
                        data-testid={testIds.QuestionHeader.onQuestionChange}
                        variant={'filled'}
                        disabled={true}
                        fullWidth={true}
                        margin={'dense'}
                        label={t('questionHeaderIdentifier')}
                        value={identifier}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} sm={5} alignContent={'flex-end'} style={{ marginBottom: 8 }}>
                <Grid item xs>
                    <TextField
                        variant={'filled'}
                        disabled={true}
                        margin={'dense'}
                        fullWidth={true}
                        label={t('questionType')}
                        value={type.toLowerCase()}
                    />
                </Grid>
            </Grid>
            {description !== identifier + '_DESCRIPTION' && (
                <Grid container item xs={12} alignContent={'flex-end'} style={{ marginBottom: 8 }}>
                    <Grid item xs>
                        <TextField
                            variant={'filled'}
                            disabled={true}
                            fullWidth={true}
                            margin={'dense'}
                            label={t('questionHeaderDescription')}
                            value={description}
                        />
                    </Grid>
                </Grid>
            )}
        </Grid>
    )
}

export default QuestionHeader
