import { Button, Grid, makeStyles, TextField, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import useI18n from '../../utils/testable/useI18n'
import testIds from '../../utils/testIds'

const useStyles = makeStyles({
    main: {
        padding: '16px',
    },
    searchButton: {
        height: '100%',
    },
})

export interface SearchBarProps {
    onSearch: (searchTerm: string) => void
    searchButtonDisabled: boolean
    searchTerm: string
}

const SearchBar = (props: SearchBarProps): JSX.Element => {
    const classes = useStyles()
    const { t } = useI18n()
    const { onSearch, searchButtonDisabled, searchTerm } = props
    const [state, setState] = useState<string>(searchTerm)

    return (
        <Grid container className={classes.main} direction={'row'} justify={'space-evenly'} spacing={2}>
            <Grid item xs={8}>
                <TextField
                    data-testid={testIds.SearchBar.onSearchTextField}
                    value={state}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
                        setState(e.target.value)
                    }}
                    onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>): void => {
                        if (e.key === 'Enter' && !searchButtonDisabled) {
                            onSearch(state)
                        }
                    }}
                    variant={'filled'}
                    fullWidth={true}
                />
            </Grid>
            <Grid container item direction={'column'} justify={'center'} xs={4}>
                <Button
                    data-testid={testIds.SearchBar.onSearchButton}
                    className={classes.searchButton}
                    fullWidth={true}
                    variant={'contained'}
                    disabled={searchButtonDisabled}
                    onClick={(): void => onSearch(state)}
                >
                    <Typography noWrap>{t('searchBarSearch')}</Typography>
                </Button>
            </Grid>
        </Grid>
    )
}

export default SearchBar
