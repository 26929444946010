export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string
    String: string
    Boolean: boolean
    Int: number
    Float: number
    JSON: any
}

export type Category = {
    __typename?: 'Category'
    id: Scalars['ID']
    name: Scalars['String']
    questions: Array<Question>
}

export type CategoryControlInput = {
    index: Scalars['Int']
    questionnaireID: Scalars['String']
}

export type CategoryCreateInput = {
    name: Scalars['String']
    questionnaireID: Scalars['String']
}

export type CategoryCreateInputInternal = {
    translationKey?: Maybe<Scalars['String']>
    translation?: Maybe<Scalars['String']>
    questionnaireID: Scalars['String']
}

export type CategoryDragDropInput = {
    sourceIndex: Scalars['Int']
    destinationIndex: Scalars['Int']
    questionnaireID: Scalars['String']
}

export type CategoryEditInput = {
    index: Scalars['Int']
    name: Scalars['String']
    questionnaireID: Scalars['String']
}

export type CategoryEditInputInputInternal = {
    index: Scalars['Int']
    translationKey?: Maybe<Scalars['String']>
    translation?: Maybe<Scalars['String']>
    questionnaireID: Scalars['String']
}

export type CategoryInternal = {
    __typename?: 'CategoryInternal'
    id: Scalars['ID']
    disabled: Scalars['Boolean']
    translationKey: Scalars['String']
    questions: Array<Scalars['ID']>
}

export enum ConditionConnector {
    AND = 'AND',
    OR = 'OR',
}

export type ConditionObjectType = {
    __typename?: 'ConditionObjectType'
    action?: Maybe<ConditionVisibility>
    questionSource?: Maybe<Scalars['String']>
    type?: Maybe<ConditionValue>
    conditionValue?: Maybe<Scalars['String']>
}

export enum ConditionValue {
    IS = 'IS',
    IS_NOT = 'IS_NOT',
    CONTAINS = 'CONTAINS',
    CONTAINS_NOT = 'CONTAINS_NOT',
}

export enum ConditionVisibility {
    SHOW_IF = 'SHOW_IF',
    SHOW_IF_NOT = 'SHOW_IF_NOT',
    HIDE_IF = 'HIDE_IF',
    HIDE_IF_NOT = 'HIDE_IF_NOT',
    MULTIPLY_BY = 'MULTIPLY_BY',
}

export enum CopyAnswerType {
    NONE = 'NONE',
    SELECTED = 'SELECTED',
}

export enum DatePickerType {
    DATE_TIME = 'DATE_TIME',
}

export enum ImportTranslationType {
    REPLACE = 'REPLACE',
    ADD = 'ADD',
}

export type MachineType = {
    __typename?: 'MachineType'
    id: Scalars['ID']
    identifier: Scalars['String']
    disabled: Scalars['Boolean']
    name: Scalars['String']
    questionnaires: Array<Questionnaire>
}

export type MachineTypeEditInput = {
    id: Scalars['String']
    identifier: Scalars['String']
    name: Scalars['String']
}

export type MachineTypeInternal = {
    __typename?: 'MachineTypeInternal'
    id: Scalars['ID']
    identifier: Scalars['ID']
    disabled: Scalars['Boolean']
    deleted: Scalars['Boolean']
    questionDefinition: Array<QuestionDefinition>
}

export type MediaConfig = {
    __typename?: 'MediaConfig'
    allowedMediaTypes: Array<Scalars['String']>
    allowMultiple: Scalars['Boolean']
}

export enum MediaRequiredType {
    BOTH = 'BOTH',
    IMAGE = 'IMAGE',
    VIDEO = 'VIDEO',
}

export type Mutation = {
    __typename?: 'Mutation'
    categoryCreate?: Maybe<QuestionnaireInternal>
    categoryDelete?: Maybe<QuestionnaireInternal>
    categoryDisable?: Maybe<QuestionnaireInternal>
    categoryEdit?: Maybe<QuestionnaireInternal>
    categoryDragDrop?: Maybe<QuestionnaireInternal>
    machineTypeDisable: Array<MachineTypeInternal>
    questionAdd: Array<Question>
    questionReAdd: Array<Scalars['String']>
    questionMultiAdd?: Maybe<QuestionnaireInternal>
    questionCreate: QuestionCreateResult
    questionDelete: CategoryInternal
    questionDisable: CategoryInternal
    questionDuplicate: QuestionCreateResult
    questionDragDrop: Array<Scalars['String']>
    moveQuestion: Array<CategoryInternal>
    questionSave?: Maybe<QuestionnaireInternal>
    questionnaireCreate?: Maybe<QuestionnaireInternal>
    questionnaireDelete: Array<QuestionnaireInternal>
    questionnaireDuplicate: Array<QuestionnaireInternal>
    questionnaireDisable: Array<QuestionnaireInternal>
    questionnaireEdit: Array<QuestionnaireInternal>
}

export type MutationCategoryCreateArgs = {
    input: CategoryCreateInputInternal
}

export type MutationCategoryDeleteArgs = {
    input: CategoryControlInput
}

export type MutationCategoryDisableArgs = {
    input: CategoryControlInput
}

export type MutationCategoryEditArgs = {
    input: CategoryEditInputInputInternal
}

export type MutationCategoryDragDropArgs = {
    input: CategoryDragDropInput
}

export type MutationMachineTypeDisableArgs = {
    identifier: Scalars['String']
}

export type MutationQuestionAddArgs = {
    input: QuestionInputAdd
}

export type MutationQuestionReAddArgs = {
    input: QuestionInputReAdd
}

export type MutationQuestionMultiAddArgs = {
    input: QuestionInputMultiAdd
}

export type MutationQuestionCreateArgs = {
    input: QuestionInput
}

export type MutationQuestionDeleteArgs = {
    input: QuestionInput
}

export type MutationQuestionDisableArgs = {
    input: QuestionInput
}

export type MutationQuestionDuplicateArgs = {
    input: QuestionInput
}

export type MutationQuestionDragDropArgs = {
    input: QuestionDragDropInput
}

export type MutationMoveQuestionArgs = {
    input: QuestionMoveToCategoryInput
}

export type MutationQuestionSaveArgs = {
    input: QuestionSaveInputInternal
}

export type MutationQuestionnaireCreateArgs = {
    input: QuestionnaireCreateInput
}

export type MutationQuestionnaireDeleteArgs = {
    id: Scalars['String']
}

export type MutationQuestionnaireDuplicateArgs = {
    id: Scalars['String']
}

export type MutationQuestionnaireDisableArgs = {
    id: Scalars['String']
}

export type MutationQuestionnaireEditArgs = {
    input: QuestionnaireEditInput
}

export type Option = {
    __typename?: 'Option'
    id: Scalars['String']
    name: Scalars['String']
    description?: Maybe<Scalars['String']>
}

export type OptionsConfig = {
    __typename?: 'OptionsConfig'
    options: Array<Option>
}

export type PdfCategoriesSaveInput = {
    pdfCategories: Array<Maybe<PdfCategoryInput>>
    questionnaireID: Scalars['String']
}

export type PdfCategoryInput = {
    visible: Scalars['Boolean']
    name: Scalars['JSON']
    questions: Array<Maybe<Scalars['String']>>
    displayInBoth?: Maybe<Scalars['Boolean']>
}

export type QTranslation = {
    __typename?: 'QTranslation'
    translationID: Scalars['ID']
    translation: Scalars['String']
}

export type Query = {
    __typename?: 'Query'
    loadTranslations: Scalars['String']
    machineTypes: Array<MachineTypeInternal>
    machineType: MachineTypeInternal
    questionnaires: Array<QuestionnaireInternal>
    questionnaire: QuestionnaireInternal
    questionnaireTranslationKeys: Array<Maybe<Scalars['String']>>
    categories: Array<Category>
    questions: Array<SearchQuestionsData>
    question: Question
    machineTypesForApp: Array<MachineType>
    questionnairesByMachineTypeIdentifier: Array<Questionnaire>
    getQuestionnaireByLangauge: Questionnaire
    questionnairesByIdentifer: Array<Questionnaire>
}

export type QueryLoadTranslationsArgs = {
    language: Scalars['String']
}

export type QueryMachineTypesArgs = {
    searchTerm?: Maybe<Scalars['String']>
}

export type QueryMachineTypeArgs = {
    id?: Maybe<Scalars['String']>
}

export type QueryQuestionnairesArgs = {
    searchTerm?: Maybe<Scalars['String']>
    machineTypeIdentifier: Scalars['String']
}

export type QueryQuestionnaireArgs = {
    id: Scalars['String']
}

export type QueryCategoriesArgs = {
    questionnaireID: Scalars['String']
}

export type QueryQuestionsArgs = {
    searchTerm: Scalars['String']
}

export type QueryQuestionArgs = {
    id: Scalars['String']
}

export type QueryMachineTypesForAppArgs = {
    language?: Maybe<Scalars['String']>
    displayDefintion?: Maybe<Scalars['Boolean']>
}

export type QueryQuestionnairesByMachineTypeIdentifierArgs = {
    identifier?: Maybe<Scalars['String']>
    language?: Maybe<Scalars['String']>
    displayDefintion?: Maybe<Scalars['Boolean']>
}

export type QueryGetQuestionnaireByLangaugeArgs = {
    id: Scalars['String']
    language?: Maybe<Scalars['String']>
}

export type QueryQuestionnairesByIdentiferArgs = {
    language?: Maybe<Scalars['String']>
    identifier: Scalars['String']
}

export type Question = {
    __typename?: 'Question'
    id: Scalars['ID']
    answerRequired: Scalars['Boolean']
    identifier: Scalars['String']
    mediaRequired: MediaRequiredType
    name: Scalars['String']
    description: Scalars['String']
    questionConfig?: Maybe<QuestionConfig>
    questionRequired: Scalars['Boolean']
    type: QuestionTypeInternal
    conditionsCombination?: Maybe<ConditionConnector>
    conditions?: Maybe<Array<Maybe<ConditionObjectType>>>
    copyAnswer?: Maybe<Scalars['String']>
    destinationList: Array<Scalars['String']>
}

export type QuestionConfig = OptionsConfig | MediaConfig

export type QuestionConfiguration = {
    __typename?: 'QuestionConfiguration'
    questionDefinitionIdentifier: Scalars['ID']
    questionDefinition: QuestionDefinition
    answerRequired: Scalars['Boolean']
    mediaRequired: MediaRequiredType
    allowMediaMultiple?: Maybe<Scalars['Boolean']>
    questionRequired: Scalars['Boolean']
    conditionsCombination?: Maybe<ConditionConnector>
    conditions?: Maybe<Array<ConditionObjectType>>
    destinationList: Array<Scalars['String']>
    disabledOptions: Array<Scalars['String']>
    options: Array<Scalars['String']>
}

export type QuestionCreateResult = {
    __typename?: 'QuestionCreateResult'
    index: Scalars['Int']
    question: Question
}

export type QuestionDefinition = {
    __typename?: 'QuestionDefinition'
    identifier: Scalars['ID']
    deleted?: Maybe<Scalars['Boolean']>
    type: QuestionTypeInternal
    options: Array<Scalars['String']>
}

export type QuestionDragDropInput = {
    sourceIndex: Scalars['Int']
    destinationIndex: Scalars['Int']
    categoryIndex: Scalars['Int']
    questionnaireID: Scalars['String']
}

export type QuestionInput = {
    categoryIndex: Scalars['Int']
    index: Scalars['Int']
    questionnaireID: Scalars['String']
}

export type QuestionInputAdd = {
    categoryIndex: Scalars['Int']
    questionnaireID: Scalars['String']
    id: Scalars['String']
    index: Scalars['Int']
}

export type QuestionInputMultiAdd = {
    ids?: Maybe<Array<Scalars['String']>>
    index: Scalars['Int']
    categoryIndex: Scalars['Int']
    questionnaireID: Scalars['String']
}

export type QuestionInputReAdd = {
    id: Scalars['String']
    index: Scalars['Int']
    categoryIndex: Scalars['Int']
    questionnaireID: Scalars['String']
}

export type QuestionMoveToCategoryInput = {
    fromIndex: Scalars['Int']
    toIndex: Scalars['Int']
    fromCategoryIndex: Scalars['Int']
    toCategoryIndex: Scalars['Int']
    questionnaireID: Scalars['String']
}

export type Questionnaire = {
    __typename?: 'Questionnaire'
    id: Scalars['ID']
    categories: Array<Category>
    name: Scalars['String']
    disabled: Scalars['Boolean']
    type: QuestionnaireType
    machineTypeIdentifier: Scalars['ID']
}

export type QuestionnaireCreateInput = {
    translationKey?: Maybe<Scalars['String']>
    translation?: Maybe<Scalars['String']>
    type: QuestionnaireType
    machineTypeIdentifier: Scalars['String']
}

export type QuestionnaireEditInput = {
    id: Scalars['String']
    type: QuestionnaireType
    translationKey?: Maybe<Scalars['String']>
    translation?: Maybe<Scalars['String']>
}

export type QuestionnaireInternal = {
    __typename?: 'QuestionnaireInternal'
    id: Scalars['ID']
    categories: Array<CategoryInternal>
    questionConfiguration: Array<QuestionConfiguration>
    disabled: Scalars['Boolean']
    translationKey: Scalars['String']
    type: QuestionnaireType
    machineTypeIdentifier: Scalars['ID']
}

export type QuestionnaireTranslation = {
    __typename?: 'QuestionnaireTranslation'
    questionnaireID: Scalars['ID']
    translations: Array<QTranslation>
}

export enum QuestionnaireType {
    DISPLAY_DEFINITION = 'DISPLAY_DEFINITION',
    QUESTIONNAIRE = 'QUESTIONNAIRE',
}

export type QuestionSaveInput = {
    questionnaireID: Scalars['String']
    index: Scalars['Int']
    categoryIndex: Scalars['Int']
    identifier: Scalars['String']
    answerRequired: Scalars['Boolean']
    description: Scalars['JSON']
    conditionsCombination: ConditionConnector
    conditions: Scalars['JSON']
    disabled: Scalars['Boolean']
    mediaRequired: MediaRequiredType
    name: Scalars['JSON']
    questionRequired: Scalars['Boolean']
    status: QuestionStatusType
    type: QuestionType
    copyAnswer?: Maybe<Scalars['String']>
    destinationList: Array<Scalars['JSON']>
    questionConfig: Scalars['JSON']
}

export type QuestionSaveInputInternal = {
    questionnaireID: Scalars['String']
    questionDefinitionIdentifier: Scalars['String']
    answerRequired: Scalars['Boolean']
    mediaRequired: MediaRequiredType
    questionRequired: Scalars['Boolean']
    allowMediaMultiple: Scalars['Boolean']
    conditionsCombination?: Maybe<ConditionConnector>
    conditions: Scalars['JSON']
    destinationList: Array<Scalars['String']>
    disabledOptions: Array<Scalars['String']>
    options: Array<Scalars['String']>
}

export enum QuestionStatusType {
    NONE = 'NONE',
    BRONZE = 'BRONZE',
    SILVER = 'SILVER',
    GOLD = 'GOLD',
}

export enum QuestionType {
    TEXT_SHORT = 'TEXT_SHORT',
    TEXT_LONG = 'TEXT_LONG',
    RADIO = 'RADIO',
    CHECKBOX = 'CHECKBOX',
    DROPDOWN = 'DROPDOWN',
    AUTOCOMPLETE = 'AUTOCOMPLETE',
    FILE_UPLOAD = 'FILE_UPLOAD',
    IMAGE_VIDEO = 'IMAGE_VIDEO',
    LOCATION = 'LOCATION',
    DATE = 'DATE',
}

export enum QuestionTypeInternal {
    NUMBER = 'NUMBER',
    STRING = 'STRING',
    DATE = 'DATE',
    BOOLEAN = 'BOOLEAN',
    ENUMLIST = 'ENUMLIST',
    ENUM = 'ENUM',
    MEDIA = 'MEDIA',
}

export type SearchQuestionsData = {
    __typename?: 'SearchQuestionsData'
    id: Scalars['ID']
    type: QuestionType
    name: Scalars['String']
    machineTypeName: Scalars['String']
    questionnaireName: Scalars['String']
    categoryName: Scalars['String']
}
