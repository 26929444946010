import { FilledInput, FormControl, InputLabel, MenuItem, Select, SelectProps } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

export interface DropdownProps extends SelectProps {
    label: string
    options: string[]
    testIdentifier?: string
    translationKey?: string
}

const Dropdown = (props: DropdownProps): JSX.Element => {
    const { t } = useTranslation()
    const { label, options, testIdentifier, translationKey, ...rest } = props

    return (
        <FormControl variant={'filled'} style={{ marginLeft: 'auto' }} fullWidth={true}>
            <InputLabel id={label}>{label}</InputLabel>
            <Select data-testid={testIdentifier} fullWidth={true} input={<FilledInput margin={'dense'} />} {...rest}>
                {options.map((option, index) => (
                    <MenuItem key={option + index} value={option}>
                        {translationKey ? t(translationKey + option) : t(option)}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default Dropdown
