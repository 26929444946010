import { Fade, Grid, IconButton, makeStyles, Tooltip, Typography } from '@material-ui/core'
import Delete from '@material-ui/icons/Delete'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import Edit from '@material-ui/icons/Edit'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import React from 'react'
import { CategoryInternal } from '../../graphql/types'
import useI18n from '../../utils/testable/useI18n'
import AddQuestionButton from '../AddQuestionButton'

const useStyles = makeStyles({
    main: { margin: '0 8px' },
    iconButtonMaster: {
        color: '#55A546',
    },
})

export interface CategoryTileProps {
    category: CategoryInternal
    index: number
    onAddQuestion: (id: string) => void
    onDelete: (id: string) => void
    onDisableToggle: (id: string) => void
    onEdit: (id: string) => void
}

const CategoryTile = (props: CategoryTileProps): JSX.Element => {
    const classes = useStyles()
    const { t } = useI18n()
    const [isMouseOver, setIsMouseOver] = React.useState(false)
    const { index, onAddQuestion, onDelete, onDisableToggle, onEdit, category } = props

    return (
        <Grid
            key={index}
            item
            xs={12}
            onMouseEnter={() => setIsMouseOver(true)}
            onMouseLeave={() => setIsMouseOver(false)}
        >
            <Grid container direction={'row'} alignItems={'center'}>
                <Grid item container xs={10} direction={'row'} alignItems={'center'} justify={'flex-start'}>
                    <Fade in={isMouseOver} timeout={400}>
                        <DragIndicatorIcon style={{ marginRight: 15, cursor: 'grab' }} />
                    </Fade>
                    <Typography style={{ marginRight: 16 }}>{t(category.translationKey)}</Typography>
                    <Tooltip
                        title={t('editCategory')}
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        arrow
                        placement={'bottom'}
                    >
                        <IconButton
                            size={'small'}
                            onClick={(event): void => {
                                event.stopPropagation()
                                onEdit(category.id)
                            }}
                        >
                            <Edit />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid container item xs={2} direction={'row'} justify={'flex-end'}>
                    <AddQuestionButton
                        onClick={(event): void => {
                            event.stopPropagation()
                            onAddQuestion(category.id)
                        }}
                    />
                    <Tooltip
                        title={t('deleteCategory')}
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        arrow
                        placement={'bottom'}
                    >
                        <IconButton
                            onClick={(event): void => {
                                event.stopPropagation()
                                onDelete(category.id)
                            }}
                            size={'small'}
                        >
                            <Delete />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title={category.disabled ? t('enableCategory') : t('disableCategory')}
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        arrow
                        placement={'bottom'}
                    >
                        <IconButton
                            className={!category.disabled ? classes.iconButtonMaster : undefined}
                            onClick={(event): void => {
                                event.stopPropagation()
                                onDisableToggle(category.id)
                            }}
                            size={'small'}
                        >
                            {category.disabled ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default CategoryTile
