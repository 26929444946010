import { Fade, Grid, IconButton, TextField, Tooltip } from '@material-ui/core'
import Delete from '@material-ui/icons/Delete'
import Dropdown from 'components/Dropdown'
import React from 'react'
import { useConditionsOptions } from 'utils/useConditionsOptions'
import { ConditionObjectType, ConditionValue, ConditionVisibility } from '../../graphql/types'
import { AutocompleteOption, AutocompleteState, ConditionTypeAction } from '../../utils/constants'
import useI18n from '../../utils/testable/useI18n'
import testIds from '../../utils/testIds'
import AutocompleteForm from '../AutocompleteForm'

export interface QuestionConditionProps {
    onConditionChange: (action: ConditionTypeAction, value?: ConditionVisibility | ConditionValue | string) => void
    conditionObjectType: ConditionObjectType
    isSecondCondition: boolean
    identifierDisable: string[]
    options: AutocompleteOption[]
}

const QuestionCondition = (props: QuestionConditionProps): JSX.Element => {
    const { t } = useI18n()
    const { onConditionChange, identifierDisable, options, conditionObjectType, isSecondCondition } = props
    const { action, questionSource, type, conditionValue } = conditionObjectType
    const { getQuestionOptions } = useConditionsOptions()
    const dropdownValueOptions = React.useMemo(() => {
        if (questionSource) return getQuestionOptions(questionSource)
        return []
    }, [questionSource, getQuestionOptions])
    const newConditionValue = React.useMemo(() => {
        if (conditionValue === 'true') return 'yes'
        if (conditionValue === 'false') return 'no'
        return conditionValue
    }, [conditionValue])

    const [state, setState] = React.useState<AutocompleteState>({
        name: null,
        inputName: '',
    })

    React.useEffect(
        () => {
            if (questionSource) {
                setState({
                    ...state,
                    name: {
                        identifier: questionSource,
                        translation: t(questionSource),
                    },
                })
            }
            return () =>
                setState({
                    name: null,
                    inputName: '',
                })
        },
        // eslint-disable-next-line
        [],
    )

    const conditionValueOptions: string[] = React.useMemo(() => {
        if (isSecondCondition)
            return [
                ConditionVisibility.SHOW_IF,
                ConditionVisibility.SHOW_IF_NOT,
                ConditionVisibility.HIDE_IF,
                ConditionVisibility.HIDE_IF_NOT,
            ]
        return [
            ConditionVisibility.SHOW_IF,
            ConditionVisibility.SHOW_IF_NOT,
            ConditionVisibility.HIDE_IF,
            ConditionVisibility.HIDE_IF_NOT,
            ConditionVisibility.MULTIPLY_BY,
        ]
    }, [isSecondCondition])

    return (
        <>
            <Grid container item direction={'row'} alignItems={'center'} justify={'flex-start'} spacing={1}>
                <Grid container item xs={4} direction={'row'} justify={'space-between'}>
                    <Dropdown
                        label={t('conditionVisibility')}
                        options={conditionValueOptions}
                        value={action}
                        onChange={(
                            event: React.ChangeEvent<{
                                name?: string | undefined
                                value: unknown
                            }>,
                        ): void =>
                            onConditionChange(ConditionTypeAction.ACTION, event.target.value as ConditionVisibility)
                        }
                        testIdentifier={testIds.QuestionCondition.onConditionActionChange}
                        translationKey={'conditionVisibility_'}
                    />
                </Grid>
                <Grid container item xs={8} direction={'row'} justify={'space-between'} spacing={1}>
                    <Grid item xs={11} style={{ marginBottom: 4 }}>
                        <AutocompleteForm
                            testId={'autocompleteQuestionCondition'}
                            identifierDisable={identifierDisable}
                            inputValue={state.inputName}
                            onChange={(value: AutocompleteOption | null): void => {
                                setState({ ...state, name: value })
                                onConditionChange(ConditionTypeAction.QUESTION_SOURCE, value?.identifier as string)
                            }}
                            onInputChange={(inputValue: string): void => setState({ ...state, inputName: inputValue })}
                            options={options}
                            margin={'dense'}
                            value={state.name}
                            label={t('selectQuestion')}
                        />
                    </Grid>
                    <Grid container item xs direction={'row'} justify={'center'} alignContent={'center'}>
                        {(!questionSource || action === ConditionVisibility.MULTIPLY_BY) && (
                            <Grid item>
                                <Tooltip
                                    title={t('deleteValue')}
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 600 }}
                                    arrow
                                    placement={'bottom'}
                                >
                                    <IconButton
                                        data-testid={testIds.QuestionCondition.onConditionDeleteClick}
                                        onClick={() => onConditionChange(ConditionTypeAction.DELETE)}
                                        size={'small'}
                                    >
                                        <Delete />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            {questionSource && action !== ConditionVisibility.MULTIPLY_BY && (
                <Grid container item direction={'row'} alignItems={'center'} justify={'flex-start'} spacing={1}>
                    <Grid container item xs={4} direction={'row'} justify={'space-between'}>
                        <Dropdown
                            label={t('conditionType')}
                            options={[
                                ConditionValue.IS,
                                ConditionValue.IS_NOT,
                                ConditionValue.CONTAINS,
                                ConditionValue.CONTAINS_NOT,
                            ]}
                            value={type}
                            onChange={(
                                event: React.ChangeEvent<{
                                    name?: string | undefined
                                    value: unknown
                                }>,
                            ): void =>
                                onConditionChange(ConditionTypeAction.TYPE, event.target.value as ConditionValue)
                            }
                            testIdentifier={testIds.QuestionCondition.onConditionTypeChange}
                            translationKey={'conditionValue_'}
                        />
                    </Grid>
                    <Grid container item xs={8} direction={'row'} justify={'space-between'} spacing={1}>
                        <Grid item xs={11} style={{ marginBottom: 4 }}>
                            {questionSource && dropdownValueOptions.length > 0 ? (
                                <Dropdown
                                    label={t('value')}
                                    options={dropdownValueOptions}
                                    value={newConditionValue}
                                    onChange={(
                                        event: React.ChangeEvent<{
                                            name?: string | undefined
                                            value: unknown
                                        }>,
                                    ): void => {
                                        let newValue = event.target.value as string
                                        if (newValue === 'yes') newValue = 'true'
                                        if (newValue === 'no') newValue = 'false'
                                        props.onConditionChange(ConditionTypeAction.VALUE, newValue as string)
                                    }}
                                    testIdentifier={testIds.QuestionCondition.onConditionChange}
                                />
                            ) : (
                                <TextField
                                    data-testid={testIds.QuestionCondition.onConditionChange}
                                    value={conditionValue ? t(conditionValue) : ''}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void =>
                                        props.onConditionChange(ConditionTypeAction.VALUE, e.target.value as string)
                                    }
                                    label={t('value')}
                                    variant={'filled'}
                                    margin="dense"
                                    fullWidth={true}
                                />
                            )}
                        </Grid>
                        <Grid container item xs direction={'row'} justify={'center'} alignContent={'center'}>
                            <Grid item>
                                <Tooltip
                                    title={t('deleteValue')}
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 600 }}
                                    arrow
                                    placement={'bottom'}
                                >
                                    <IconButton
                                        data-testid={testIds.QuestionCondition.onConditionDeleteClick}
                                        onClick={() => props.onConditionChange(ConditionTypeAction.DELETE)}
                                        size={'small'}
                                    >
                                        <Delete />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </>
    )
}

export default QuestionCondition
