// Import locales here:
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import de from './de.json'
import en from './en.json'

// add languages to the resources
const resources = {
    en: en.en,
    de: de.de,
}

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: 'en',
        keySeparator: '.', // we use keys in form messages.welcome
        interpolation: {
            escapeValue: false, // react already prevents xss
        },
    })

export default i18n
