import { Checkbox, FormControlLabel, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import useI18n from '../../utils/testable/useI18n'
import testIds from '../../utils/testIds'

const useStyles = makeStyles({
    main: {
        padding: '16px 0',
    },
    formControl: {
        minWidth: '150px',
    },
    formControlAutocomplete: {
        minWidth: '150px',
        zIndex: 99,
    },
})

export interface QuestionFooterProps {
    answerRequired: boolean
    onAnswerRequiredToggle: (bool: boolean) => void
    onQuestionRequiredToggle: (bool: boolean) => void
    questionRequired: boolean
}

const QuestionFooter = (props: QuestionFooterProps): JSX.Element => {
    const classes = useStyles()
    const { t } = useI18n()
    const { answerRequired, onAnswerRequiredToggle, onQuestionRequiredToggle, questionRequired } = props
    return (
        <Grid container direction={'row'} spacing={2} className={classes.main}>
            <Grid container item justify={'space-between'} xs={12}>
                <FormControlLabel
                    data-testid={testIds.QuestionFooter.onAnswerRequiredCheck}
                    control={<Checkbox checked={answerRequired} />}
                    onChange={(_, checked: boolean): void => onAnswerRequiredToggle(checked)}
                    label={t('questionFooterAnswerRequired')}
                />
                <FormControlLabel
                    data-testid={testIds.QuestionFooter.onQuestionRequiredCheck}
                    control={<Checkbox checked={questionRequired} />}
                    onChange={(_, checked: boolean): void => onQuestionRequiredToggle(checked)}
                    label={t('questionFooterQuestionRequired')}
                />
            </Grid>
        </Grid>
    )
}

export default QuestionFooter
