import { Fab, Fade, Grid, makeStyles, Tooltip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import React from 'react'
import useI18n from '../../utils/testable/useI18n'
import testIds from '../../utils/testIds'

const useStyles = makeStyles({
    controlMargin: {
        margin: '12px 10px 10px 10px',
    },
})

export interface CategoryCreationButtonProps {
    onClick: () => void
}

const CategoryCreationButton = (props: CategoryCreationButtonProps): JSX.Element => {
    const classes = useStyles()
    const { t } = useI18n()
    const { onClick } = props

    return (
        <Grid container alignItems={'center'} item xs={12} justify={'flex-end'}>
            <Tooltip
                title={t('categoryCreationButton')}
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                arrow
                placement={'bottom'}
            >
                <Fab
                    data-testid={testIds.CategoryCreationButton.onAdd}
                    className={classes.controlMargin}
                    onClick={onClick}
                    size={'small'}
                >
                    <AddIcon />
                </Fab>
            </Tooltip>
        </Grid>
    )
}

export default CategoryCreationButton
