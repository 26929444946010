import _ from "lodash";
import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { useAuth0 } from "./reactAuth0Wrapper";

const PrivateRoute = React.memo(
    ({ component: Component, path, ...rest }) => {
        const { loading, isAuthenticated, loginWithRedirect } = useAuth0();

        useEffect(() => {
            if (loading || isAuthenticated) {
                return;
            }
            const fn = async () => {
                await loginWithRedirect({
                    appState: { targetUrl: path }
                });
            };
            fn();
        }, [isAuthenticated, loading, loginWithRedirect, path]);

        const render = props => (isAuthenticated === true ? <Component {...props} /> : null);

        return <Route path={path} render={render} {...rest} />;
    },
    (prevProps, nextProps) => {
        return _.isEqual(prevProps, nextProps);
    }
);

export default PrivateRoute;
