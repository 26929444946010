import { useLazyQuery } from '@apollo/react-hooks'
import { CircularProgress, Grid } from '@material-ui/core'
import i18n from 'i18next'
import React from 'react'
import loadTranslations from '../graphql/queries/loadTranslations'
import { AutocompleteOption } from './constants'
import { useAuth0 } from './reactAuth0Wrapper'

export interface TranslationWrapperProps {
    children: JSX.Element
}

export interface TranslationContexProps {
    loadTranslationIdentifier: (filter?: string) => AutocompleteOption[]
}

export const TranslationContext = React.createContext<TranslationContexProps>({
    loadTranslationIdentifier: () => {
        return []
    },
})
export const useTranslation = (): TranslationContexProps => React.useContext(TranslationContext)
export const TranslationWrapper = (props: TranslationWrapperProps): JSX.Element => {
    const [translations, setTranslations] = React.useState<Record<string, string>>({})
    const [loading, setLoading] = React.useState<boolean>(true)
    const { isAuthenticated } = useAuth0()

    const [getTranslationsString] = useLazyQuery(loadTranslations, {
        variables: {
            language: 'en',
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        onCompleted: (data: any) => {
            if (data && data.loadTranslations) {
                const tr = JSON.parse(data.loadTranslations)
                setTranslations(tr)
                i18n.addResourceBundle('en', 'translation', tr)
                setLoading(false)
            }
        },
    })

    React.useEffect(() => {
        if (isAuthenticated) {
            getTranslationsString()
        }
        // eslint-disable-next-line
    }, [isAuthenticated])

    const loadTranslationIdentifier = (filter?: string): AutocompleteOption[] => {
        const list: AutocompleteOption[] = []
        for (const identifier in translations) {
            if (filter === undefined || identifier.startsWith(filter)) {
                list.push({ identifier, translation: translations[identifier] })
            }
        }
        return list
    }

    if (!isAuthenticated) {
        return <>{props.children}</>
    }

    if (loading) {
        return (
            <Grid
                container
                item
                direction={'row'}
                xs={12}
                justify={'center'}
                alignItems={'center'}
                style={{ marginTop: '30%' }}
            >
                <CircularProgress />
            </Grid>
        )
    }

    return (
        <TranslationContext.Provider
            value={{
                loadTranslationIdentifier,
            }}
        >
            {props.children}
        </TranslationContext.Provider>
    )
}
