import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import React from 'react'
import { AutocompleteOption, AutocompleteState, DialogType } from '../../utils/constants'
import { disabledSaveAutocomplete } from '../../utils/testable/autoCompleteFunc'
import useI18n from '../../utils/testable/useI18n'
import testIds from '../../utils/testIds'
import AutocompleteForm from '../AutocompleteForm'

export interface AutocompleteDialogProps {
    identifierDisable: string[]
    onCLose: () => void
    onCreate: (name: AutocompleteOption | null, inputName: string) => void
    options: AutocompleteOption[]
    open: boolean
    type: DialogType
    value?: AutocompleteOption
}

const AutocompleteDialog = (props: AutocompleteDialogProps): JSX.Element => {
    const { t } = useI18n()
    const { identifierDisable, onCLose, onCreate, options, open, type, value } = props
    const [state, setState] = React.useState<AutocompleteState>({
        name: value ? value : null,
        inputName: '',
    })

    return (
        <Dialog
            disableBackdropClick={false}
            disableEscapeKeyDown={false}
            aria-labelledby={'autocomplete-dialog-title'}
            open={open}
            maxWidth={'sm'}
            fullWidth={true}
            style={{ height: '75%' }}
        >
            <DialogTitle id={'autocomplete-dialog-title'}>{t('autocompleteDialog.title_' + type)}</DialogTitle>
            <DialogContent style={{ padding: '24px' }} dividers>
                <AutocompleteForm
                    testId={'autocompleteDialog'}
                    identifierDisable={identifierDisable}
                    inputValue={state.inputName}
                    onChange={(value: AutocompleteOption | null): void => setState({ ...state, name: value })}
                    onInputChange={(inputValue: string): void => setState({ ...state, inputName: inputValue })}
                    options={options}
                    value={state.name}
                    label={t('autocompleteDialog.label_' + type)}
                />
            </DialogContent>
            <DialogActions>
                <Button data-testid={testIds.AutocompleteDialog.onCLose} onClick={onCLose}>
                    {t('labelCancel')}
                </Button>
                <Button
                    data-testid={testIds.AutocompleteDialog.onSave}
                    disabled={disabledSaveAutocomplete(state.inputName, identifierDisable, options)}
                    onClick={(): void => {
                        onCreate(state.name, state.inputName)
                        setState({ name: null, inputName: '' })
                    }}
                >
                    {t('labelSave')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AutocompleteDialog
