import { FormControl, makeStyles } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React from 'react'
import { AutocompleteOption } from '../../utils/constants'
import { filterOptions, getOptionLabel } from '../../utils/testable/autoCompleteFunc'
import useI18n from '../../utils/testable/useI18n'
import testIds from '../../utils/testIds'
import RenderInput from './RenderInput'
import RenderOption from './RenderOption'

const useStyles = makeStyles({
    root: {
        width: '100%',
        zIndex: 99,
    },
})

export interface QuestionCopyAnswerProps {
    onChange: (value: AutocompleteOption[]) => void
    options: AutocompleteOption[]
    value: AutocompleteOption[]
    label: string
}
const QuestionCopyAnswer = (props: QuestionCopyAnswerProps): JSX.Element => {
    const classes = useStyles()
    const { t } = useI18n()
    const { onChange, options, value, label } = props
    const [state, setState] = React.useState<AutocompleteOption[]>(value)
    const onChangeLocal = (e: any, newValue: AutocompleteOption[]): void => {
        setState(newValue)
        onChange(newValue)
    }
    return (
        <FormControl className={classes.root} variant={'filled'}>
            <Autocomplete
                data-testid={testIds.QuestionCopyAnswer.autocomplete}
                multiple={true}
                disableClearable={true}
                autoHighlight={true}
                freeSolo={true}
                disableCloseOnSelect={true}
                value={state}
                options={options}
                getOptionLabel={getOptionLabel}
                renderOption={RenderOption}
                filterOptions={filterOptions}
                onChange={onChangeLocal}
                renderInput={RenderInput(t(label))}
            />
        </FormControl>
    )
}

export default QuestionCopyAnswer
